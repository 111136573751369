import { KeyboardEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import { markdown2Html } from '../../../../common/markdown/markdown-provider';
import { IDiagramsFolder } from '../../diagrams-interfaces';
import DiagramsEditFolder from './folder-edit';

export const DIAGRAMS_FOLDER_DESCRIPTION_EVENT = 'DIAGRAMS_FOLDER_DESCRIPTION_EVENT';

export enum DiagramsFolderDescriptionMode {
    DEFAULT = 0,
    EDIT = 1,
}

interface IDiagramsFolderDescription {
    folder: IDiagramsFolder;
}

export const isDescExists = (desc: string) => {
    return desc !== undefined &&
        desc !== null &&
        desc.trim() !== '';
};

const DiagramsFolderDescription = ({ folder }: IDiagramsFolderDescription) => {

    const [mode, setMode] = useState<DiagramsFolderDescriptionMode>(DiagramsFolderDescriptionMode.DEFAULT);

    const descExists = isDescExists(folder.description);

    const parsedDesc = descExists ? markdown2Html(folder.description) : '';

    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        contentRef?.current?.focus();
    }, []);

    const setDefaultMode = () => {
        setMode(DiagramsFolderDescriptionMode.DEFAULT);
    };

    const startEdit = () => {
        setMode(DiagramsFolderDescriptionMode.EDIT);
    };

    useEffect(() => {

        const handler = (evt: CustomEvent) => {
            const folder_id = Number(evt?.detail) || 0;

            if(folder_id !== folder.id) return;
            setMode(DiagramsFolderDescriptionMode.EDIT);
        };

        document.addEventListener(DIAGRAMS_FOLDER_DESCRIPTION_EVENT, handler);

        return () => {
            document.removeEventListener(DIAGRAMS_FOLDER_DESCRIPTION_EVENT, handler);
        };
    }, [folder.id]);

    const onClick = (evt: MouseEvent<HTMLDivElement>) => {
        contentRef?.current?.focus();

        // double-click
        if(evt.detail === 2) {
            startEdit();
        }
    };

    const onKeyDown = (evt: KeyboardEvent<HTMLDivElement>) => {
        if(evt.code === 'Escape') {
            setDefaultMode();
        }
    };

    return (
        <>
            {
                (descExists && mode === DiagramsFolderDescriptionMode.DEFAULT) &&
                <div className="flex flex-col">
                    <div
                        ref={ contentRef }
                        tabIndex={ 0 }
                        className="text-xs overflow-auto max-h-[500px] rounded my-1 outline-none"
                        onClick={ onClick }
                        onKeyDown={ onKeyDown }>
                        <div
                            className="markdown whitespace-pre-wrap"
                            dangerouslySetInnerHTML={{__html: parsedDesc }}
                        />
                    </div>
                </div>
            }

            {
                (mode === DiagramsFolderDescriptionMode.EDIT) &&
                <DiagramsEditFolder
                    folder={ folder }
                    setMode={ setDefaultMode }
                />
            }
        </>
    )
};

export default DiagramsFolderDescription;