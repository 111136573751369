import { ITodoAttachment } from '../../../planner-interfaces';
import TodoDeleteAttachmentButton from './todo-attachment-delete';
import { checkIsImage } from '../../../../../common/domain/file-provider';
import TodoAttachmentEdit from './todo-attachment-edit';
import { useState } from 'react';
import TodoAttachmentEditButton from './todo-attachment-edit-btn';

enum AttachmentMode {
    DEFAULT = 0,
    EDIT = 1,
}

interface ITodoAttachmentProps {
    attachment: ITodoAttachment;
}

const TodoAttachment = ({ attachment }: ITodoAttachmentProps) => {

    const [mode, setMode] = useState<AttachmentMode>(AttachmentMode.DEFAULT);

    const path = `/api/plan/todo/attachment/get?path=${ attachment.attachment_path }`;
    const isImage = checkIsImage(path);

    const startEditDescription = () => {
        setMode(AttachmentMode.EDIT);
    };

    const stopEditDescription = () => {
        setMode(AttachmentMode.DEFAULT);
    };

    return (
        <div className="flex flex-col">
            {
                isImage &&
                <a
                    className="attachment__box flex flex-col mb-2"
                    href={ path }
                    rel="noreferrer"
                    target="_blank">

                    <div className="relative flex">
                        <img
                            className="rounded mt-2"
                            src={ path }
                            alt={ attachment.attachment_desc }
                        />

                        <div className="attachment__action hidden flex justify-center items-center mb-2 absolute right-[3px] bottom-0 bg-white rounded p-1">
                            <TodoDeleteAttachmentButton
                                attachment={ attachment }
                            />

                            <TodoAttachmentEditButton
                                startEditDescription={ startEditDescription }
                            />
                        </div>
                    </div>

                    {
                        attachment.attachment_desc &&
                        <div className="italic mt-1">{ attachment.attachment_desc }</div>
                    }

                </a>
            }

            {
                !isImage &&
                <a className="attachment__box flex flex-col my-2 leading-6"
                   href={ path }
                   rel="noreferrer"
                   target="_blank">

                    <div className="flex items-center relative">
                        <div className="border rounded py-2 px-4 bg-white mr-4">{ attachment.attachment_path }</div>
                        <div className="attachment__action hidden">

                            <TodoDeleteAttachmentButton
                                attachment={ attachment }
                            />

                            <TodoAttachmentEditButton
                                startEditDescription={ startEditDescription }
                            />
                        </div>
                    </div>

                    {
                        attachment.attachment_desc &&
                        <div className="italic mt-1">{ attachment.attachment_desc }</div>
                    }

                </a>
            }

            {
                mode === AttachmentMode.EDIT &&
                <TodoAttachmentEdit
                    attachment={ attachment }
                    stopEditDescription={ stopEditDescription }
                />
            }
        </div>
    )
};

export default TodoAttachment;