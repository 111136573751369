import { CalendarIcon } from '../../../../common/ui/icons';
import { getDailyCalories } from '../../domain/food-provider';

const FoodCalendarTitle = () => {

    const dailyCalories = getDailyCalories();

    return (
        <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="text-xl xl:text-3xl flex items-center text-slate-700">
                <CalendarIcon classes="mr-2"/>
                Food Calendar
            </div>

            <div>
                <strong>Норма: </strong>
                { dailyCalories.toLocaleString() } ккал
            </div>
        </div>
    )
};

export default FoodCalendarTitle;