import { create, StoreApi, UseBoundStore } from 'zustand';
import { ITodo, ITodoDiagram } from '../../planner/planner-interfaces';
import { DiagramSvg } from '../domain/diagram-elements/diagram-svg';
import { v4 as uuidv4 } from 'uuid';
import { DiagramElement } from '../domain/diagram-elements/diagram-element';

export interface TodoDiagramState {
    todo: ITodo;
    setTodo: (_todo: ITodo) => void;

    todoDiagram: ITodoDiagram|null;
    setTodoDiagram: (_todoDiagram: ITodoDiagram|null) => void;

    data: DiagramSvg|null;
    setData: (_data: DiagramSvg|null) => void;

    dataStack: string[];
    setDataStack: (_dataStack: string[]) => void;

    selectedElement: DiagramElement|null;
    setSelectedElement: (_selectedElement: DiagramElement|null) => void;

    copiedElement: DiagramElement|null;
    setCopiedElement: (_copiedElement: DiagramElement|null) => void;

    onClose: () => void;

    // Alternative: handling the case, when we don't have specific _todo.
    save?: (json: string) => Promise<void>;
}

const INITIAL_WIDTH = 800;
const INITIAL_HEIGHT = 500;

export const createTodoDiagramStore = (
    todo: ITodo,
    todoDiagram: ITodoDiagram|null,
    onClose: () => void,

    // Alternative: handling the case, when we don't have specific _todo.
    save?: (json: string) => Promise<void>,
    outerJson?: string,
) => {

    const json = save ? (outerJson || '') : (todoDiagram?.diagram_json || '');
    const data = json ? DiagramSvg.fromJson(json) : new DiagramSvg(uuidv4(), 0, 0, INITIAL_WIDTH, INITIAL_HEIGHT, []);

    return create<TodoDiagramState>()((set) => ({

        onClose,

        todo,
        setTodo: (_todo: ITodo) => {
            return set((state: TodoDiagramState) => {
                return {
                    ...state,
                    todo: _todo,
                };
            });
        },

        todoDiagram: todoDiagram || null,
        setTodoDiagram: (_todoDiagram: ITodoDiagram|null) => {
            return set((state: TodoDiagramState) => {
                return {
                    ...state,
                    todoDiagram: _todoDiagram,
                };
            });
        },

        data,
        setData: (_data: DiagramSvg|null) => {
            return set((state: TodoDiagramState) => {

                return {
                    ...state,
                    data: _data,
                };
            });
        },

        dataStack: todoDiagram?.diagram_json ? [todoDiagram.diagram_json] : [],
        setDataStack: (_dataStack: []) => {
            return set((state: TodoDiagramState) => {
                return {
                    ...state,
                    dataStack: _dataStack,
                };
            });
        },

        selectedElement: null,
        setSelectedElement: (_selectedElement: DiagramElement|null) => {
            return set((state: TodoDiagramState) => {
                return {
                    ...state,
                    selectedElement: _selectedElement,
                };
            });
        },

        copiedElement: null,
        setCopiedElement: (_copiedElement: DiagramElement|null) => {
            return set((state: TodoDiagramState) => {
                return {
                    ...state,
                    copiedElement: _copiedElement,
                };
            });
        },

        save,
    }))
}

export type TodoDiagramUseStore = UseBoundStore<StoreApi<TodoDiagramState>>;