import { ITodo, ITodoDiagram } from '../../../planner-interfaces';
import { DiagramSvg } from '../../../../todo-diagram-editor/domain/diagram-elements/diagram-svg';
import { MouseEvent, useState } from 'react';
import TodoDiagramTitle from './todo-diagram-title';
import TodoDiagramEntryPoint from '../../../../todo-diagram-editor/diagram-entry-point';

enum DiagramMode {
    DEFAULT = 0,
    EDIT = 1,
}

interface ITodoDiagramPreviewProps {
    diagram: ITodoDiagram;
    todo: ITodo;
}

const TodoDiagramPreview = ({ diagram, todo }: ITodoDiagramPreviewProps) => {

    const [diagramMode, setDiagramMode] = useState<DiagramMode>(DiagramMode.DEFAULT);

    const startEditDiagram = () => {
        setDiagramMode(DiagramMode.EDIT);
    };

    const stopEditDiagram = () => {
        setDiagramMode(DiagramMode.DEFAULT);
    };

    const onDiagramClick = (evt: MouseEvent<HTMLDivElement>) => {
        evt.stopPropagation();

        // double-click
        if(evt.detail === 2) {
            startEditDiagram();
        }
    };

    const diagramSvg = diagram.diagram_json ? DiagramSvg.fromJson(diagram.diagram_json) : null;
    const svg = diagramSvg?.toSvg();

    return (
        <div className="diagram-preview-box flex flex-col bg-white border rounded my-2 overflow-hidden">
            <TodoDiagramTitle
                diagram={ diagram }
                startEditDiagram={ startEditDiagram }
            />

            <div className="flex flex-col">

                {
                    diagramMode === DiagramMode.DEFAULT &&
                    <>
                        {
                            diagram.diagram_json &&
                            <div
                                onClick={ onDiagramClick }
                                className="max-w-full pb-4"
                                dangerouslySetInnerHTML={{__html: svg || '' }}
                            />
                        }

                        {
                            !diagram.diagram_json &&
                            <div
                                onClick={ onDiagramClick }
                                className="w-full h-24 flex items-center justify-center">
                                An Empty Diagram
                            </div>
                        }
                    </>
                }

                {
                    diagramMode === DiagramMode.EDIT &&
                    <TodoDiagramEntryPoint
                        todo={ todo }
                        todoDiagram={ diagram }
                        onClose={ stopEditDiagram }
                    />
                }
            </div>
        </div>
    )
};

export default TodoDiagramPreview;