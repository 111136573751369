import { PlusIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { ChangeEvent, useCallback, useEffect, useRef, useState, KeyboardEvent as ReactKeyboardEvent } from 'react';
import { handleWiziwig } from '../../../../planner/domain/wiziwig-provider';
import { showToast } from '../../../../../common/domain/toast-provider';
import { useDiagramsStore } from '../../../data/diagrams-store';
import { insertDiagram } from '../../../data/diagrams-data-service';
import { formatDiagramsResponse, setDiagramsFolderOpenedClosed } from '../../../domain/diagrams-provider';
import { openDiagramFolder } from '../../../domain/diagrams-storage';

enum Mode {
    DEFAULT = 0,
    ADDING = 1,
}

const AddDiagram = () => {

    const diagramsResponse = useDiagramsStore(state => state.diagramsResponse);
    const setDiagramsResponse = useDiagramsStore(state => state.setDiagramsResponse);

    const [mode, setMode] = useState(Mode.DEFAULT);
    const [isLoading, setLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [titleTouched, setTitleTouched] = useState(false);

    const isTitleValid = !titleTouched || title.trim().length > 0;

    const titleRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        titleRef?.current?.focus();
    }, [mode]);

    const startAddingDiagram = () => {
        setMode(Mode.ADDING);
    };

    const stopAddingDiagram = () => {
        setMode(Mode.DEFAULT);
    };

    const onTitleChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setTitle((evt.target as HTMLInputElement).value);
        setTitleTouched(true);
    };

    const save = useCallback(async () => {
        if(title.trim().length <= 0) {
            setTitleTouched(true);
            return;
        }

        setLoading(true);

        const selectedFolderId = diagramsResponse?.selectedFolder?.id ?? 0;

        const response = await insertDiagram(
            title.trim(),
            '',
            selectedFolderId,
        );

        setLoading(false);

        if(!response) {
            showToast('Insert diagram error.');
            return;
        }

        stopAddingDiagram();

        openDiagramFolder(selectedFolderId);

        setDiagramsResponse(setDiagramsFolderOpenedClosed(formatDiagramsResponse(response)));
    }, [
        title,
        diagramsResponse?.selectedFolder?.id,
        setDiagramsResponse,
    ]);

    useEffect(() => {

        const onKeyDown = async (evt: KeyboardEvent) => {

            if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
                evt.preventDefault();
                await save();
                return;
            }
        };

        document.addEventListener('keydown', onKeyDown, true);

        return () => {
            document.removeEventListener('keydown', onKeyDown, true);
        };

    }, [save]);

    const onKeyDown = async (evt: ReactKeyboardEvent<HTMLInputElement>) => {
        if(handleWiziwig(evt, titleRef, (newText) => {
            setTitle(newText);
            setTitleTouched(true);
        })){
            return;
        }

        if(evt.code === 'Escape') {
            stopAddingDiagram();
        }

        if(evt.code === 'Enter') {
            await save();
        }
    };

    return (
        <>
            {
                mode === Mode.DEFAULT &&
                <button
                    onClick={ startAddingDiagram }
                    type="button"
                    className="flex items-center text-sm mt-4 mb-10 text-slate-400">

                    <div className="pr-3">Add Diagram</div>
                    <PlusIcon />

                </button>
            }

            {
                mode === Mode.ADDING &&
                <div className="mt-4 mb-10">
                    <input
                        ref={ titleRef }
                        onInput={ onTitleChange }
                        onKeyDown={ onKeyDown }
                        className={ `bg-slate-100 text-slate-900 w-full rounded px-2 py-1 outline-none ${ isTitleValid ? '' : 'border-2 border-red-500' }` }
                        type="text"
                    />

                    {
                        !isTitleValid && <div className="text-red-500 text-xs mt-1">The title is required.</div>
                    }

                    <div className="flex items-center justify-end text-sm mt-4">
                        <button
                            className="bg-stone-400 text-slate-100 rounded px-4 py-2 mr-2"
                            onClick={ stopAddingDiagram }
                            type="button">Cancel</button>

                        {
                            isLoading &&
                            <PreloaderIcon size={ 24 } color={ '#717985' } />
                        }

                        {
                            !isLoading &&
                            <button
                                className="bg-slate-500 text-slate-100 rounded px-6 py-2"
                                onClick={ save }
                                type="button">Save</button>
                        }
                    </div>
                </div>
            }
        </>
    )
};

export default AddDiagram;
