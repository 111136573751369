import { useState } from 'react';
import { DeleteIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { showToast } from '../../../../../common/domain/toast-provider';
import { useNavigate } from 'react-router-dom';
import { deleteDiagramFolder } from '../../../data/diagrams-data-service';
import { formatDiagramsResponse } from '../../../domain/diagrams-provider';
import { useDiagramsStore } from '../../../data/diagrams-store';

interface IDiagramsDeleteFolderButton {
    id: number;
    title: string;
}

const DiagramsDeleteFolderButton = ({ id, title }: IDiagramsDeleteFolderButton) => {

    const [isLoading, setLoading] = useState(false);

    const setDiagramsResponse = useDiagramsStore(store => store.setDiagramsResponse);

    const navigate = useNavigate();

    const deleteFolderHandler = async () => {

        if(!confirm(`Are you sure you want to delete the folder "${ title }"?`)) return;

        setLoading(true);
        const response = await deleteDiagramFolder(id);
        setLoading(false);

        if(!response) {
            showToast('Delete folder error.');
            return;
        }

        setDiagramsResponse(formatDiagramsResponse(response));

        navigate('/draw-io');
    };

    return (
        <>
            {
                isLoading &&
                <PreloaderIcon size={ 20 } color={ '#717985' } />
            }

            {
                !isLoading &&
                <button
                    title="Delete Folder"
                    className="diagrams-folder__delete flex bg-pink-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
                    onClick={ deleteFolderHandler }
                    type="button">
                    <DeleteIcon size={ 20 } />
                </button>
            }
        </>
    )
};

export default DiagramsDeleteFolderButton;