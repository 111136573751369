import { DiagramElement } from './diagram-element';
import { DiagramElementType } from './diagram-element-type-enum';
import { DiagramRectangleWithText } from './elements/diagram-rectangle-with-text';
import { DiagramLine } from './elements/diagram-line';
import { DiagramArrow } from './elements/diagram-arrow';
import { DiagramText } from './elements/diagram-text';

export class DiagramSvg {
    id: string;
    x: number;
    y: number;
    width: number;
    height: number;
    children?: DiagramElement[];

    constructor(
        id: string,
        x: number,
        y: number,
        width: number,
        height: number,
        children?: DiagramElement[],
    ) {
        this.id = id;
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
        this.children = children ?? [];
    }

    clone() : DiagramSvg {
        return new DiagramSvg(
            this.id,
            this.x,
            this.y,
            this.width,
            this.height,
            this.children
        );
    }

    toSvg() : string {
       try{
           return `<svg
                class="max-w-full mx-auto"
                width="${ this.width }"
                height="${ this.height }"
                viewBox="${ this.x } ${ this.y } ${ this.width } ${ this.height }">
                ${  this.children.map(shape => shape.toSvg() ).join('')  }
            </svg>`;
       }
       catch (ex) {
           console.log(ex);
           return '';
       }
    }

    public static fromJson(json: string): DiagramSvg {
        try{
            const parsed = json ? JSON.parse(json) : null;
            if(!parsed) return null;

            return new DiagramSvg(
                parsed.id,
                parsed.x,
                parsed.y,
                parsed.width,
                parsed.height,
                parsed.children?.map((shape: DiagramElement) => {
                    switch (shape.type) {
                        case DiagramElementType.RoundedRectangleWithText: {
                            return DiagramRectangleWithText.fromJson(shape as DiagramRectangleWithText);
                        }
                        case DiagramElementType.RectangleWithText: {
                            return DiagramRectangleWithText.fromJson(shape as DiagramRectangleWithText);
                        }
                        case DiagramElementType.Text: {
                            return DiagramText.fromJson(shape as DiagramText);
                        }
                        case DiagramElementType.Line: {
                            return DiagramLine.fromJson(shape as DiagramLine);
                        }
                        case DiagramElementType.Arrow: {
                            return DiagramArrow.fromJson(shape as DiagramArrow);
                        }
                    }
                }),
            );
        }
        catch (ex) {
            console.error(ex);
            return null;
        }
    }
}
