import Actions, { sendCloseActionsEvent } from '../../../../../common/ui/actions';
import DeleteTransaction from '../actions/delete-transaction';
import { EditIcon } from '../../../../../common/ui/icons';
import { IFinanceTransactionItem } from '../../../finance-interfaces';
import { useFinanceStore } from '../../../data/finance-store';
import { Fragment } from 'react';
import { getTransactionSourceName } from '../../../domain/finance-storage';
import { marked } from 'marked';

interface ITransactionItem {
    transaction: IFinanceTransactionItem;
    index: number;
}

const TransactionItem = ({ transaction, index }: ITransactionItem) => {

    const setTransactionEditPopup = useFinanceStore(store => store.setTransactionEditPopup);

    const startEdit = () => {
        setTransactionEditPopup(transaction);
        sendCloseActionsEvent();
    };

    return (
        <Fragment key={ transaction.TransactionID }>
            <tr>
                <td
                    rowSpan={ transaction.TransactionDescription ? 2 : 1 }
                    className="border border-b-2 border-l-2 px-4 py-2 text-center"
                    style={{
                        backgroundColor: transaction.CategoryColor,
                    }}
                >{ index + 1 }</td>

                <td className="border px-4 py-2">
                    { transaction.TransactionDay }/{ transaction.TransactionMonth + 1 }/{ transaction.TransactionYear }
                </td>

                <td className="border px-4 py-2">{transaction.TransactionAmount.toLocaleString()}</td>
                <td className="border px-4 py-2"
                    style={{
                        backgroundColor: 'rgb(249 250 252)',
                    }}>
                    <button type="button"
                            title="Edit Transaction"
                            className="text-left"
                            onClick={ startEdit }>
                        {transaction.TransactionBusinessName || 'Untitled'}
                    </button>
                </td>
                <td className="border px-4 py-2">
                    {
                        (transaction.TransactionPaymentsNumber > 0) &&
                        <span>
                        {transaction.TransactionPaymentNumber} from {transaction.TransactionPaymentsNumber}
                    </span>
                    }
                </td>
                <td className="border px-4 py-2">{ getTransactionSourceName(transaction.TransactionSource) }</td>
                <td className="border px-4 py-2">{transaction.CategoryName}</td>
                <td className="border px-4 py-2">{transaction.TransactionConfirmationNumber}</td>
                <td className="border border-r-2 px-4 py-2 text-center transaction-actions">
                    <Actions classes="transaction__actions" gridSize={ 2 } mr="mr-0" ml="ml-0" pl="pl-0">
                        <DeleteTransaction
                            transaction={ transaction }
                        />

                        <button
                            title="Edit Transaction"
                            className="transaction__edit flex bg-slate-200 rounded mx-1 text-left justify-center items-center whitespace-nowrap action-btn"
                            onClick={ startEdit }
                            type="button">
                            <EditIcon color={ '#6e7781' } size={ 20 } />
                        </button>
                    </Actions>
                </td>

            </tr>

            {
                transaction.TransactionDescription &&
                <tr>
                    <td
                        colSpan={ 8 }
                        className="border border-b-2 border-r-2 px-4 py-2">
                        <div
                            className="markdown-editor"
                            dangerouslySetInnerHTML={ { __html: marked.parse(transaction.TransactionDescription) }}
                        />
                    </td>
                </tr>
            }
        </Fragment>
    )
};

export default TransactionItem;