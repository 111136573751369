import { create } from 'zustand';
import { DiagramsView, IDiagramsResponse } from '../diagrams-interfaces';

interface DiagramsState {

    view: DiagramsView,
    setView: (_view: DiagramsView) => void;

    diagramsResponse: IDiagramsResponse|null;
    setDiagramsResponse: (_diagramsResponse: IDiagramsResponse|null) => void;
}

export const useDiagramsStore = create<DiagramsState>()((set) => ({

    view: DiagramsView.ROOT,
    setView: (_view: DiagramsView) => {
        return set((state: DiagramsState) => {
            return {
                ...state,
                view: _view,
            };
        });
    },

    diagramsResponse: null,
    setDiagramsResponse: (_diagramsResponse: IDiagramsResponse|null) => {
        return set((state: DiagramsState) => {
            return {
                ...state,
                diagramsResponse: _diagramsResponse,
            };
        });
    },
}))