import MobileMenuBtn from '../../../menu/ui/mobile-menu-btn';
import Menu from '../../../menu/menu';
import DiagramsMenu from './menu/diagrams-menu';
import { MouseEvent, useEffect } from 'react';
import { getDiagramsData } from '../data/diagrams-data-service';
import { useDiagramsStore } from '../data/diagrams-store';
import { showToast } from '../../../common/domain/toast-provider';
import { formatDiagramsResponse } from '../domain/diagrams-provider';
import { useParams } from 'react-router-dom';
import { DiagramsView } from '../diagrams-interfaces';
import RootView from './root-view';
import FolderView from './folder/folder-view';
import DiagramView from './diagram/diagram-view';

const EntryPoint = () => {

    const { selected_item_id } = useParams();

    const view = useDiagramsStore(store => store.view);
    const setView = useDiagramsStore(store => store.setView);

    const setDiagramsResponse = useDiagramsStore(store => store.setDiagramsResponse);

    useEffect(() => {
        if(!selected_item_id) {
            setView(DiagramsView.ROOT);
            return;
        }

        if(selected_item_id?.startsWith('f-')) {
            setView(DiagramsView.FolderView);
            return;
        }

        setView(DiagramsView.DiagramView);
    }, [
        selected_item_id,
        setView,
    ]);

    useEffect(() => {
        (async () => {

            const folder_id = Number(selected_item_id?.replace('f-', '')) || 0;
            const response = await getDiagramsData(folder_id);

            if(!response) {
                showToast('Get diagrams data error.');
                return;
            }

            setDiagramsResponse(formatDiagramsResponse(response));
        })();
    }, [setDiagramsResponse, selected_item_id]);


    const stopPropagation = (evt: MouseEvent<HTMLDivElement>) => {
        evt.stopPropagation();
    };

    return (
        <div className="flex min-h-screen relative overflow-hidden">
            <MobileMenuBtn />

            <div
                onClick={ stopPropagation }
                className="mobile-menu-sidebar flex absolute z-[2000] right-0 translate-x-full transition-transform xl:static xl:translate-x-0">
                <Menu />
                <DiagramsMenu />
            </div>

            <div className="flex flex-col flex-1 leading-7 h-screen relative z-50 bg-white w-screen">

                {
                    view === DiagramsView.ROOT &&
                    <RootView />
                }

                {
                    view === DiagramsView.FolderView &&
                    <FolderView />
                }

                {
                    view === DiagramsView.DiagramView &&
                    <DiagramView />
                }
            </div>
        </div>
    )
};

export default EntryPoint;