import { DragEvent } from 'react';
import { DragIcon } from '../../../../../common/ui/icons';
import { IDiagram } from '../../../diagrams-interfaces';

const DRAG_CLASS = 'diagrams-dragging';

interface IDragDiagramBtnProps {
    diagram: IDiagram;
}

const DragDiagramBtn = ({ diagram }: IDragDiagramBtnProps) => {

    const sourceOnDragStart = (evt: DragEvent<HTMLButtonElement>) => {
        evt.stopPropagation();

        // Clear the actions' data cache (for all formats/types)
        evt.dataTransfer.clearData();

        evt.dataTransfer.setData('text/plain', diagram.id.toString());

        // https://stackoverflow.com/questions/19639969/html5-dragend-event-firing-immediately
        ((_currentTarget) => {
            window.setTimeout(() => {
                document.body.classList.add(DRAG_CLASS);
            }, 0)
        })(evt.currentTarget);
    };

    const sourceOnDragEnd = (evt: DragEvent<HTMLButtonElement>) => {
        evt.stopPropagation();
        document.body.classList.remove(DRAG_CLASS);
    };

    const sourceOnDrag = (evt: DragEvent<HTMLButtonElement>) => {
        evt.stopPropagation();
    };

    return(
        <button
            type="button"
            draggable={ true }
            onDragStart={ sourceOnDragStart }
            onDragEnd={ sourceOnDragEnd }
            onDrag={ sourceOnDrag }
            title="Drag Diagram"
            className="diagrams-menu__drag-diagram-btn cursor-move flex bg-purple-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn">
            <DragIcon
                size={ 20 }
                color={ '#603c86' }
            />
        </button>
    )
};

export default DragDiagramBtn;
