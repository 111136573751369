import { DiagramElementType } from './diagram-element-type-enum';

export abstract class DiagramElement {
    id: string;
    type: DiagramElementType;

    protected constructor(
        id: string,
        type: DiagramElementType,
    ) {
        this.id = id;
        this.type = type;
    }

    abstract clone() : DiagramElement;
    abstract toSvg() : string;
}
