import { useEffect } from 'react';
import { showToast } from '../../../../common/domain/toast-provider';
import FoodItemsTabsNavigation, { FoodItemsTab } from './tabs-navigation';
import { useFoodStore } from '../../data/food-store';
import { getFood } from '../../data/food-data-service';
import FoodItemsList from './list/food-items-list';
import FoodsPagination from './list/pagination';
import FoodCategoriesRadioFilters from './radio-filters/categories-radio-filters';
import SearchFoods from './list/search';
import AddFood from './actions/add-food';
import EditFoodPopup from './actions/edit-food';

const FoodItemsHome = () => {

    const foodItemsData = useFoodStore(store => store.foodItemsData);
    const setFoodItemsData = useFoodStore(store => store.setFoodItemsData);
    const foodItemsTab = useFoodStore(store => store.foodItemsTab);

    const foodItemsPageNumber = useFoodStore(store => store.foodItemsPageNumber);
    const foodItemsSelectedCategoryID = useFoodStore(store => store.foodItemsSelectedCategoryID);
    const foodItemsSearchTerm = useFoodStore(store => store.foodItemsSearchTerm);

    useEffect(() => {
        (async () => {
            const response = await getFood(
                foodItemsPageNumber,
                foodItemsSelectedCategoryID,
                foodItemsSearchTerm,
            );

            if(!response) {
                showToast('Get food data error.');
                return;
            }

            setFoodItemsData(response);
        })();
    }, [
        setFoodItemsData,
        foodItemsPageNumber,
        foodItemsSelectedCategoryID,
        foodItemsSearchTerm,
    ]);

    return (
        <div className="xl:max-w-[800px] max-w-full mx-auto py-10">
            <h1 className="flex items-center text-xl xl:text-3xl mb-6">
                Food Items
            </h1>

            <FoodItemsTabsNavigation />

            {
                foodItemsTab === FoodItemsTab.Default &&
                <>
                    <div className="flex flex-col">
                        <FoodCategoriesRadioFilters />
                        <SearchFoods />
                    </div>

                    {
                        foodItemsData?.foods?.length > 0 &&
                        <>
                            <FoodItemsList />
                            <FoodsPagination />
                            <EditFoodPopup />
                        </>
                    }
                </>
            }

            {
                foodItemsTab === FoodItemsTab.AddNew &&
                <AddFood />
            }

        </div>
    )
};

export default FoodItemsHome;