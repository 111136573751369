import { CloseIcon } from '../../../../../common/ui/icons';
import { TodoDiagramUseStore } from '../../../data/store';

export interface ICloseDiagramBtnProps {
    useStore: TodoDiagramUseStore;
}

const CloseDiagramPreviewBtn = ({ useStore }: ICloseDiagramBtnProps) => {

    const onClose = useStore(state => state.onClose);

    const onClick = () => {
        onClose();
    };

    return (
        <button
            className="ml-4"
            type="button"
            onClick={ onClick }>
            <CloseIcon />
        </button>
    )
};

export default CloseDiagramPreviewBtn;