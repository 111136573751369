import { NavLink } from 'react-router-dom';
import { toggleMobileMenuBodyClass } from '../../../../../menu/ui/mobile-menu-provider';
import { IDiagram } from '../../../diagrams-interfaces';
import Actions from '../../../../../common/ui/actions';
import DiagramDeleteButton from '../../diagram/actions/diagram-delete-btn';
import EditDiagram from '../../diagram/actions/edit-diagram';
import DragDiagramBtn from '../../diagram/drag/drag-diagram-btn';
import DuplicateDiagramButton from '../../diagram/actions/dulicate-diagram-btn';

interface IDiagramMenuItemProps {
    diagram: IDiagram;
}

const DiagramMenuItem = ({ diagram }: IDiagramMenuItemProps) => {

    return (
        <>
            <div className="diagrams__menu-item flex border-b border-slate-700 py-3 px-3 flex items-center whitespace-pre-wrap">
                <NavLink
                    className="flex items-center flex-1"
                    to={ `/draw-io/${ diagram.id }` }
                    onClick={ toggleMobileMenuBodyClass }>
                    <div className="w-[5px] h-[5px] rounded-full mr-4 bg-blue-500"></div>
                    { diagram.title }
                </NavLink>

                {/*{
                    selectedDeck?.deck_id === deck.deck_id &&
                    <DragDeckBtn deck={ deck } />
                }*/}

                <Actions classes="diagram__actions" gridSize={ 2 } mr="mr-0">

                    <DiagramDeleteButton
                        id={ diagram.id }
                        title={ diagram.title }
                    />

                    <EditDiagram
                        diagram={ diagram }
                    />

                    <DuplicateDiagramButton
                        diagram={ diagram }
                    />

                    <DragDiagramBtn
                        diagram={ diagram }
                    />
                </Actions>
            </div>


           {/* <DragCardToAnotherDeckTarget
                targetDeckId={ deck.deck_id }
            />*/}
        </>
    )
};

export default DiagramMenuItem;
