import { TodoDiagramUseStore } from '../../data/store';
import SvgSelectionPoint from './svg-selection-point';
import { SVG_SELECTION_SIZE } from '../../domain/todo-diagram-provider';

interface ISvgSelectionProps {
    useStore: TodoDiagramUseStore;
}

const SvgSelection = ({ useStore }: ISvgSelectionProps) => {

    const data = useStore(state => state.data);
    
    const points = [
        // Top-left
        {
            x: data.x,
            y: data.y,
            cursor: 'e-resize'
        },

        // Top-right
        {
            x: data.x + data.width - SVG_SELECTION_SIZE,
            y: data.y,
            cursor: 'w-resize'
        },

        // Bottom-left
        {
            x: data.x,
            y: data.y + data.height - SVG_SELECTION_SIZE,
            cursor: 'nesw-resize'
        },

        // Bottom-right
        {
            x: data.x + data.width - SVG_SELECTION_SIZE,
            y: data.y + data.height - SVG_SELECTION_SIZE,
            cursor: 'nwse-resize'
        },
    ];

    return (
        <g>
            {
                points.map((point, index) => (
                    <SvgSelectionPoint
                        key={ index }
                        index={ index }
                        x={ point.x }
                        y={ point.y }
                        cursor={ point.cursor }
                        useStore={ useStore }
                    />
                ))
            }
        </g>
    )
};

export default SvgSelection;