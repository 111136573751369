import {
    getConsumedCalories,
    getDailyCalories,
} from '../../domain/food-provider';
import { useFoodStore } from '../../data/food-store';

const TopBar = () => {

    const foodLogData = useFoodStore(store => store.foodLogData);

    const dailyCalories = getDailyCalories();
    // const dailyPFC = getPFC(dailyCalories);

    const consumed = getConsumedCalories(foodLogData?.foodLogs);

    const consumedCalories = consumed?.calories || 0;
    const consumedCaloriesDiff = dailyCalories - consumedCalories;

   /* const consumedFats = consumed?.fat || 0;
    const consumedFatsDiff = dailyPFC.fats - consumedFats;

    const consumedProtein = consumed?.protein || 0;
    const consumedProteinDiff = dailyPFC.proteins - consumedProtein;

    const consumedCarbohydrates = consumed?.carbohydrates || 0;
    const consumedCarbohydratesDiff = dailyPFC.carbohydrates - consumedCarbohydrates;*/

    return (
        <div  className="bg-stone-50 p-3 text-sm grid gap-4 mb-12">
            <div className="grid md:block">
                <span className="mr-4">Калории: <strong>{ consumedCalories.toFixed(2) } of { dailyCalories.toFixed(2) }</strong> </span>
                <span className="mr-4">Остаток: <strong>{ consumedCaloriesDiff.toFixed(2) }</strong></span>
            </div>

            {/*<div>
                <span className="mr-4">Жиры: { consumedFats.toFixed(2) } of { dailyPFC.fats.toFixed(2) }</span>
                <span className="mr-4">Остаток: { consumedFatsDiff.toFixed(2) }</span>
            </div>

            <div>
                <span className="mr-4">Белки: { consumedProtein.toFixed(2) } of { dailyPFC.proteins.toFixed(2) }</span>
                <span className="mr-4">Остаток: { consumedProteinDiff.toFixed(2) }</span>
            </div>

            <div>
                <span className="mr-4">Углеводы: { consumedCarbohydrates.toFixed(2) } of { dailyPFC.carbohydrates.toFixed(2) }</span>
                <span className="mr-4">Остаток: { consumedCarbohydratesDiff.toFixed(2) }</span>
            </div>*/}
        </div>
    )
};

export default TopBar;