import { ChangeEvent, KeyboardEvent as ReactKeyboardEvent, useEffect, useRef, useState } from 'react';
import { handleWiziwig } from '../../../../planner/domain/wiziwig-provider';
import { PreloaderIcon } from '../../../../../common/ui/icons';
import { useFinanceStore } from '../../../data/finance-store';
import { insertTransaction } from '../../../data/finance-data-service';
import { showToast } from '../../../../../common/domain/toast-provider';
import { TransactionsTab } from '../tabs-navigation';
import TransactionActionsMonthsDropDown from './drop-downs/months-drop-down';
import TransactionActionsDaysDropDown from './drop-downs/days-drop-down';
import TransactionActionsYearsDropDown from './drop-downs/years-drop-down';
import { TransactionSource } from '../../../finance-interfaces';
import TransactionSourceDropDown from './drop-downs/transaction-source-drop-down';
import { MDXEditor, MDXEditorMethods } from '@mdxeditor/editor';
import { getCompactMarkdownEditorPlugins } from '../../../../../common/markdown/markdown-editor-provider';

const AddTransaction = () => {

    const nameRef = useRef<HTMLInputElement>(null);
    const editorRef = useRef<MDXEditorMethods>(null);

    const setFinanceTransactionsData = useFinanceStore(store => store.setFinanceTransactionsData);
    const setTransactionsTab = useFinanceStore(store => store.setTransactionsTab);

    const transactionsPageNumber = useFinanceStore(store => store.transactionsPageNumber);
    const transactionsSelectedYear = useFinanceStore(store => store.transactionsSelectedYear);
    const transactionsSelectedMonth = useFinanceStore(store => store.transactionsSelectedMonth);
    const transactionsSelectedCategoryID = useFinanceStore(store => store.transactionsSelectedCategoryID);

    const [name, setName] = useState('');
    const [nameTouched, setNameTouched] = useState(false);

    const [day, setDay] = useState((new Date).getDate());
    const [month, setMonth] = useState((new Date).getMonth());
    const [year, setYear] = useState((new Date).getFullYear());

    const [paymentNumber, setPaymentNumber] = useState(0);
    const [paymentsNumber, setPaymentsNumber] = useState(0);
    const [confirmationNumber, setConfirmationNumber] = useState('');
    const [transactionSource, setTransactionSource] = useState<TransactionSource>(TransactionSource.BankLeumi);

    const [amount, setAmount] = useState(0);
    const [amountTouched, setAmountTouched] = useState(false);

    const [isLoading, setLoading] = useState(false);

    const isNameValid = !nameTouched || name.trim().length > 0;
    const isAmountValid = !amountTouched || amount > 0;

    useEffect(() => {
        nameRef?.current?.focus();
    }, []);

    const save = async () => {

        if(name.trim().length <= 0) {
            setNameTouched(true);
            return;
        }

        setLoading(true);

        const description = editorRef.current?.getMarkdown() || '';

        const response = await insertTransaction(
            transactionsPageNumber,
            transactionsSelectedYear,
            transactionsSelectedMonth,
            transactionsSelectedCategoryID,

            day,
            month,
            year,

            name,
            description,
            confirmationNumber,
            amount,
            transactionSource,
            paymentNumber,
            paymentsNumber,
        );

        setLoading(false);

        if(!response) {
            showToast('Insert transaction error.');
            return;
        }

        setFinanceTransactionsData(response);
        setTransactionsTab(TransactionsTab.Default);
    };

    const onNameChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setName(evt.target.value);
        setNameTouched(true);
    };

    const onAmountChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setAmount(Number(evt.target.value) || 0);
        setAmountTouched(true);
    };

    const onTransactionSourceChange = (_transactionSource: TransactionSource) => {
        setTransactionSource(_transactionSource);
    };

    const onPaymentNumberChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const _paymentNumber = Number(evt.target.value) || 0;
        setPaymentNumber(_paymentNumber);
    };

    const onPaymentsNumberChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const _paymentsNumber = Number(evt.target.value) || 0;
        setPaymentsNumber(_paymentsNumber);
    };

    const onConfirmationNumberChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const _confirmationNumber = evt.target.value;
        setConfirmationNumber(_confirmationNumber);
    };

    const onNameKeyDown = async (evt: ReactKeyboardEvent<HTMLInputElement>) => {
        if(handleWiziwig(evt, nameRef, (newText) => {
            setName(newText);
            setNameTouched(true);
        })){
            return;
        }

        if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
            evt.preventDefault();
            await save();
            return;
        }

        if(evt.code === 'Enter') {
            await save();
        }
    };

    const onGeneralKeyDown = async (evt: ReactKeyboardEvent<HTMLInputElement|HTMLTextAreaElement>) => {

        if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
            evt.preventDefault();
            await save();
            return;
        }

        if(evt.code === 'Enter') {
            await save();
        }
    };

    return (
        <div>
            <label className="flex flex-col mb-4">
                <div className="text-slate-400 mb-1">Transaction Business Name</div>
                <input
                    ref={ nameRef }
                    value={ name }
                    onInput={ onNameChange }
                    onKeyDown={ onNameKeyDown }
                    className={ `border rounded px-4 py-2 ${ isNameValid ? 'outline-stone-200' : 'outline-red-200 border-red-200' }` }
                    type="text"
                />

                {
                    !isNameValid && <div className="text-red-700 text-xs mt-1">The name is required.</div>
                }
            </label>

            <div className="grid grid-cols-3 gap-4 mb-4">

                <div className="flex flex-col">
                    <label className="text-sm text-gray-400 mb-1">Day</label>
                    <TransactionActionsDaysDropDown
                        transactionsSelectedDay={ day }
                        setTransactionsSelectedDay={ setDay }
                    />
                </div>

                <div className="flex flex-col">
                    <label className="text-sm text-gray-400 mb-1">Month</label>
                    <TransactionActionsMonthsDropDown
                        transactionsSelectedMonth={ month }
                        setTransactionsSelectedMonth={ setMonth }
                    />
                </div>

                <div className="flex flex-col">
                    <label className="text-sm text-gray-400 mb-1">Year</label>
                    <TransactionActionsYearsDropDown
                        transactionsSelectedYear={ year }
                        setTransactionsSelectedYear={ setYear }
                    />
                </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
                <label className="flex flex-col mb-4">
                    <div className="text-slate-400 mb-1">Amount</div>
                    <input
                        value={ amount }
                        onInput={ onAmountChange }
                        onKeyDown={ onGeneralKeyDown }
                        className={ `border rounded px-4 py-1 ${ isAmountValid ? 'outline-stone-200' : 'outline-red-200 border-red-200' }` }
                        type="text"
                    />

                    {
                        !isAmountValid && <div className="text-red-700 text-xs mt-1">The amount is required.</div>
                    }
                </label>

                <label className="flex flex-col mb-4">
                    <div className="text-slate-400 mb-1">Transaction Source</div>
                    <TransactionSourceDropDown
                        transactionSource={ transactionSource }
                        setTransactionsSource={ onTransactionSourceChange }
                    />
                </label>

            </div>

            <label className="flex flex-col mb-4">
                <div className="text-slate-400 mb-1">Transaction Description</div>
                <div className="markdown-editor border rounded-lg mb-4 bg-white">
                    <MDXEditor
                        ref={ editorRef }
                        markdown={ '' }
                        plugins={ getCompactMarkdownEditorPlugins() }
                    />
                </div>
            </label>

            <div className="grid grid-cols-2 gap-4">
                <label className="flex flex-col mb-4">
                    <div className="text-slate-400 mb-1">Payment Number</div>
                    <input
                        value={ paymentNumber }
                        onInput={ onPaymentNumberChange }
                        onKeyDown={ onGeneralKeyDown }
                        className={ `border rounded px-4 py-2` }
                        type="text"
                    />
                </label>

                <label className="flex flex-col mb-4">
                    <div className="text-slate-400 mb-1">Payments Number</div>
                    <input
                        value={ paymentsNumber }
                        onInput={ onPaymentsNumberChange }
                        onKeyDown={ onGeneralKeyDown }
                        className={ `border rounded px-4 py-2` }
                        type="text"
                    />
                </label>

            </div>

            <label className="flex flex-col mb-4">
                <div className="text-slate-400 mb-1">Confirmation Number</div>
                <input
                    value={ confirmationNumber }
                    onInput={ onConfirmationNumberChange }
                    onKeyDown={ onGeneralKeyDown }
                    className={ `border rounded px-4 py-2` }
                    type="text"
                />
            </label>

            <div className="flex items-center justify-end text-sm mt-4">

                {
                    isLoading &&
                    <PreloaderIcon size={ 24 } color={ '#717985' } />
                }

                {
                    !isLoading &&
                    <button
                        className="bg-slate-500 text-slate-100 rounded px-6 py-2"
                        onClick={ save }
                        type="button">Save</button>
                }
            </div>
        </div>
    )
};

export default AddTransaction;