import { getJsonFromLocalStorage, saveJsonToLocalStorage } from '../../../common/data/storage-provider';

const DIAGRAMS_OPENED_FOLDERS_KEY = 'DIAGRAMS_OPENED_FOLDERS_KEY';

/**
 * All folders are closed by default.
 * Only opened folders are stored in the local storage.
 */
export const getOpenedDiagramFolders = () : Set<number> => {
    const folder_ids = getJsonFromLocalStorage(DIAGRAMS_OPENED_FOLDERS_KEY) || [];
    return new Set<number>(folder_ids);
};

export const openDiagramFolder = (folder_id: number) : number[] => {
    const folders = getOpenedDiagramFolders();
    folders.add(folder_id);

    const list = Array.from(folders);
    saveJsonToLocalStorage(DIAGRAMS_OPENED_FOLDERS_KEY, list);
    return list;
};

export const closeDiagramFolder = (folder_id: number) : number[] => {
    const folders = getOpenedDiagramFolders();
    folders.delete(folder_id);

    const list = Array.from(folders);
    saveJsonToLocalStorage(DIAGRAMS_OPENED_FOLDERS_KEY, list);
    return list;
};
