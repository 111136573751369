import { DragEvent } from 'react';
import { moveDiagramToAnotherTodo } from '../../../data/planner-data-service';
import { showToast } from '../../../../../common/domain/toast-provider';
import { combineProjectData } from '../../../domain/planner-provider';
import { usePlannerStore } from '../../../data/planner-store';

interface ITodoDiagramDragTargetProps {
    new_todo_id: number|string;
    classes?: string;
}

const CLASS_NAME = 'todo-diagram__drag-target__over';

const TodoDiagramDragTarget = ({ new_todo_id, classes }: ITodoDiagramDragTargetProps) => {

    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);

    const targetOnDragOver = (evt: DragEvent<HTMLDivElement>) => {
        evt.preventDefault();
    };

    const targetOnDragEnter = (evt: DragEvent<HTMLDivElement>) => {
        evt.currentTarget.classList.add(CLASS_NAME);
    };

    const targetOnDragLeave = (evt: DragEvent<HTMLDivElement>) => {
        evt.currentTarget.classList.remove(CLASS_NAME);
    };

    /**
     * https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer/setData
     */
    const targetOnDrop = async (evt: DragEvent<HTMLDivElement>) => {
        evt.preventDefault();
        const data = evt.dataTransfer.getData('text');

        if(data === null ||
            data === undefined ||
            data.trim() === '' ||
            isNaN(Number(data))) {
            showToast('Drop diagram error.');
            return;
        }

        const diagram_id = Number(data);

        const response = await moveDiagramToAnotherTodo(
            diagram_id,
            new_todo_id
        );

        if(!response) {
            showToast('Drag diagram error.');
            return;
        }

        setCombinedProject(combineProjectData(response));
    };

    return (
        <div
            onDragOver={ targetOnDragOver }
            onDragEnter={ targetOnDragEnter }
            onDragLeave={ targetOnDragLeave }
            onDrop={ targetOnDrop }
            className={ `todo-diagram__drag-target w-full h-[5px] bg-slate-300 rounded mt-1 hidden ${ classes ?? '' }` }
        />
    )
};

export default TodoDiagramDragTarget;