import { ChangeEvent, KeyboardEvent as ReactKeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { handleWiziwig } from '../../../../planner/domain/wiziwig-provider';
import { PreloaderIcon } from '../../../../../common/ui/icons';
import { showToast } from '../../../../../common/domain/toast-provider';
import { useFoodStore } from '../../../data/food-store';
import { insertFood } from '../../../data/food-data-service';
import { FoodItemsTab } from '../tabs-navigation';
import FoodCategoryDropDown from '../food-category-drop-down';
import { getFoodCategoryFromStorage } from '../../../domain/food-storage';
import { MDXEditor, MDXEditorMethods } from '@mdxeditor/editor';
import { getCompactMarkdownEditorPlugins } from '../../../../../common/markdown/markdown-editor-provider';

const AddFood = () => {

    const nameRef = useRef<HTMLInputElement>(null);
    const editorRef = useRef<MDXEditorMethods>(null);

    const foodItemsData = useFoodStore(store => store.foodItemsData);
    const setFoodItemsData = useFoodStore(store => store.setFoodItemsData);
    const setFoodItemsTab = useFoodStore(store => store.setFoodItemsTab);

    const foodItemsPageNumber = useFoodStore(store => store.foodItemsPageNumber);
    const foodItemsSelectedCategoryID = useFoodStore(store => store.foodItemsSelectedCategoryID);
    const foodItemsSearchTerm = useFoodStore(store => store.foodItemsSearchTerm);

    const [name, setName] = useState('');
    const [nameTouched, setNameTouched] = useState(false);

    const [calories, setCalories] = useState(0);
    const [protein, setProtein] = useState(0);
    const [fat, setFat] = useState(0);
    const [carbohydrates, setCarbohydrates] = useState(0);

    const [categoryId, setCategoryId] = useState(getFoodCategoryFromStorage());
    const [categoryIdTouched, setCategoryIdTouched] = useState(false);

    const [isLoading, setLoading] = useState(false);

    const isNameValid = !nameTouched || name.trim().length > 0;
    const isCategoryIdValid = !categoryIdTouched || categoryId > 0;

    useEffect(() => {
        nameRef?.current?.focus();
    }, []);

    const save = useCallback(async () => {

        if(name.trim().length <= 0) {
            setNameTouched(true);
            return;
        }

        if(categoryId <= 0) {
            setCategoryIdTouched(true);
            return;
        }

        setLoading(true);

        const desc = editorRef.current?.getMarkdown() || '';

        const response = await insertFood(
            name,
            desc,
            categoryId,
            calories,
            protein,
            fat,
            carbohydrates,

            foodItemsPageNumber,
            foodItemsSelectedCategoryID,
            foodItemsSearchTerm,
        );

        setLoading(false);

        if(!response) {
            showToast('Insert food error.');
            return;
        }

        setFoodItemsData(response);
        setFoodItemsTab(FoodItemsTab.Default);
    }, [
        calories,
        carbohydrates,
        categoryId,
        fat,
        foodItemsPageNumber,
        foodItemsSearchTerm,
        foodItemsSelectedCategoryID,
        name,
        protein,
        setFoodItemsData,
        setFoodItemsTab,
    ]);

    const onNameChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setName(evt.target.value);
        setNameTouched(true);
    };

    const onCaloriesChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setCalories(Number(evt.target.value) || 0);
    };

    const onFatChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setFat(Number(evt.target.value) || 0);
    };

    const onProteinChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setProtein(Number(evt.target.value) || 0);
    };

    const onCarbohydratesChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setCarbohydrates(Number(evt.target.value) || 0);
    };

    const onCategoryIdChange = (_categoryId: number) => {
        setCategoryId(_categoryId);
        setCategoryIdTouched(true);
    };

    const onNameKeyDown = async (evt: ReactKeyboardEvent<HTMLInputElement>) => {
        if(handleWiziwig(evt, nameRef, (newText) => {
            setName(newText);
            setNameTouched(true);
        })){
            return;
        }

        if(evt.code === 'Enter') {
            await save();
        }
    };

    const onGeneralKeyDown = async (evt: ReactKeyboardEvent<HTMLInputElement|HTMLTextAreaElement>) => {
        if(evt.code === 'Enter') {
            await save();
        }
    };

    useEffect(() => {

        const onKeyDown = async (evt: KeyboardEvent) => {

            if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
                evt.preventDefault();
                await save();
                return;
            }
        };

        document.addEventListener('keydown', onKeyDown, true);

        return () => {
            document.removeEventListener('keydown', onKeyDown, true);
        };

    }, [save]);

    return (
        <div>
            <label className="flex flex-col mb-4">
                <div className="text-slate-400 mb-1">Food Name</div>
                <input
                    ref={ nameRef }
                    value={ name }
                    onInput={ onNameChange }
                    onKeyDown={ onNameKeyDown }
                    className={ `border rounded px-4 py-2 ${ isNameValid ? 'outline-stone-200' : 'outline-red-200 border-red-200' }` }
                    type="text"
                />

                {
                    !isNameValid && <div className="text-red-700 text-xs mt-1">The name is required.</div>
                }
            </label>

            <div className="grid grid-cols-2 gap-4">

                <label className="flex flex-col mb-4">
                    <div className="text-slate-400 mb-1">Food Category</div>
                    <FoodCategoryDropDown
                        categoryId={ categoryId }
                        onCategoryIdChange={ onCategoryIdChange }
                        categories={ foodItemsData?.categories || [] }
                    />

                    {
                        !isCategoryIdValid && <div className="text-red-700 text-xs mt-1">The category is required.</div>
                    }
                </label>

            </div>

            <label className="font-bold flex flex-col text-slate-400 mb-2">Food Description</label>
            <div className="markdown-editor border rounded-lg mb-4">
                <MDXEditor
                    ref={ editorRef }
                    markdown={ '' }
                    plugins={ getCompactMarkdownEditorPlugins() }
                />
            </div>

            <div className="grid grid-cols-2 gap-4">
                <label className="flex flex-col mb-4">
                    <div className="text-slate-400 mb-1">Калории</div>
                    <input
                        value={ calories }
                        onInput={ onCaloriesChange }
                        onKeyDown={ onGeneralKeyDown }
                        className={ `border rounded px-4 py-2` }
                        type="number"
                        step=".01"
                    />
                </label>

                <label className="flex flex-col mb-4">
                    <div className="text-slate-400 mb-1">Белки (граммы)</div>
                    <input
                        value={ protein }
                        onInput={ onProteinChange }
                        onKeyDown={ onGeneralKeyDown }
                        className={ `border rounded px-4 py-2` }
                        type="number"
                        step=".01"
                    />
                </label>

            </div>

            <div className="grid grid-cols-2 gap-4">
                <label className="flex flex-col mb-4">
                    <div className="text-slate-400 mb-1">Жиры (граммы)</div>
                    <input
                        value={ fat }
                        onInput={ onFatChange }
                        onKeyDown={ onGeneralKeyDown }
                        className={ `border rounded px-4 py-2` }
                        type="number"
                        step=".01"
                    />
                </label>

                <label className="flex flex-col mb-4">
                    <div className="text-slate-400 mb-1">Углеводы (граммы)</div>
                    <input
                        value={ carbohydrates }
                        onInput={ onCarbohydratesChange }
                        onKeyDown={ onGeneralKeyDown }
                        className={ `border rounded px-4 py-2` }
                        type="number"
                        step=".01"
                    />
                </label>

            </div>

            <div className="flex items-center justify-end text-sm mt-4">

                {
                    isLoading &&
                    <PreloaderIcon size={ 24 } color={ '#717985' } />
                }

                {
                    !isLoading &&
                    <button
                        className="bg-slate-500 text-slate-100 rounded px-6 py-2"
                        onClick={ save }
                        type="button">Save</button>
                }
            </div>
        </div>
    )
};

export default AddFood;