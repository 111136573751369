import FoodCategoriesTabsNavigation, { FoodCategoriesTab } from './tabs-navigation';
import { useFoodStore } from '../../data/food-store';
import { useEffect } from 'react';
import { showToast } from '../../../../common/domain/toast-provider';
import { getAllFoodCategories } from '../../data/food-data-service';
import FoodCategoriesList from './list/food-categories-list';
import AddNewFoodCategory from './actions/add-new-food-category';

const FoodCategoriesHome = () => {

    const foodCategoriesData = useFoodStore(store => store.foodCategoriesData);
    const setFoodCategoriesData = useFoodStore(store => store.setFoodCategoriesData);
    const foodCategoriesTab = useFoodStore(store => store.foodCategoriesTab);

    useEffect(() => {
        (async () => {
            const response = await getAllFoodCategories();

            if(!response) {
                showToast('Get food categories error.');
                return;
            }

            setFoodCategoriesData(response);
        })();
    }, [setFoodCategoriesData]);

    return (
        <div className="xl:max-w-[800px] max-w-full mx-auto py-10">
            <h1 className="flex items-center text-xl xl:text-3xl mb-6">
                Food Categories
            </h1>

            <FoodCategoriesTabsNavigation />

            {
                foodCategoriesTab === FoodCategoriesTab.Default &&
                foodCategoriesData?.categories?.length > 0 &&
                <>
                    <FoodCategoriesList />
                </>

            }

            {
                foodCategoriesTab === FoodCategoriesTab.AddNew &&
                <AddNewFoodCategory />
            }

        </div>
    )
};

export default FoodCategoriesHome;