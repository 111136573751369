import { DragEvent } from 'react';
import { DragIcon } from '../../../../../../common/ui/icons';
import { ITodoDiagram } from '../../../../planner-interfaces';

interface IDragDiagramTodoButtonProps {
    diagram: ITodoDiagram;
}

const CLASS_NAME = 'todo-diagram-dragging';

const DragDiagramTodoButton = ({ diagram }: IDragDiagramTodoButtonProps) => {

    /**
     * https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer/setData
     */
    const sourceOnDragStart = (evt: DragEvent<HTMLButtonElement>) => {
        evt.stopPropagation();

        // Clear the actions data cache (for all formats/types)
        evt.dataTransfer.clearData();

        evt.dataTransfer.setData('text/plain', diagram.diagram_id.toString());

        // https://stackoverflow.com/questions/19639969/html5-dragend-event-firing-immediately
        ((_currentTarget) => {
            window.setTimeout(() => {
                document.body.classList.add(CLASS_NAME);
            }, 0)
        })(evt.currentTarget);
    };

    const sourceOnDragEnd = (evt: DragEvent<HTMLButtonElement>) => {

        evt.stopPropagation();

        document.body.classList.remove(CLASS_NAME);
    };

    const sourceOnDrag = (evt: DragEvent<HTMLButtonElement>) => {
        evt.stopPropagation();
    };

    return (
        <button
            title="Drag Diagram"
            type="button"
            draggable={ true }
            onDragStart={ sourceOnDragStart }
            onDragEnd={ sourceOnDragEnd }
            onDrag={ sourceOnDrag }
            className="todo-diagram__drag-btn cursor-move flex items-center justify-center mx-1 border border-purple-200 bg-purple-100 rounded action-btn">
            <DragIcon color={ '#6B22A8' } />
        </button>
    )
};

export default DragDiagramTodoButton;