import { useEffect, useState } from 'react';
import TextBox from '../../../../common/ui/forms/text-box';
import Actions from '../../../../common/ui/actions';
import { getTextFromMarkdown, markdown2Html } from '../../../../common/markdown/markdown-provider';
import { showToast } from '../../../../common/domain/toast-provider';
import { useDiagramsStore } from '../../data/diagrams-store';
import { IDiagramsFolder } from '../../diagrams-interfaces';
import { updateDiagramFolderTitle } from '../../data/diagrams-data-service';
import { formatDiagramsResponse } from '../../domain/diagrams-provider';
import DiagramsDeleteFolderButton from './actions/folder-delete-btn';
import EditDiagramsFolderDescriptionBtn from './actions/edit-folder-description-btn';

export enum DiagramsFolderTitleMode {
    DEFAULT = 0,
    EDIT = 1,
}

interface IDiagramsFolderTitleProps {
    diagramsFolder: IDiagramsFolder;
}

const DiagramsFolderTitle = ({ diagramsFolder }: IDiagramsFolderTitleProps) => {

    const [updateTitleLoading, setUpdateTitleLoading] = useState(false);
    const [mode, setMode] = useState<DiagramsFolderTitleMode>(DiagramsFolderTitleMode.DEFAULT);

    const setDiagramsResponse = useDiagramsStore(store => store.setDiagramsResponse);

    useEffect(() => {
        document.title = getTextFromMarkdown(diagramsFolder.title);
    }, [diagramsFolder.title]);

    const startEdit = () => {
        setMode(DiagramsFolderTitleMode.EDIT);
    };

    const cancelEdit = () => {
        setMode(DiagramsFolderTitleMode.DEFAULT);
    };

    const save = async (updatedText: string) => {

        setUpdateTitleLoading(true);
        const response = await updateDiagramFolderTitle(
            diagramsFolder.id,
            updatedText.trim(),
        );

        setUpdateTitleLoading(false);

        if(!response) {
            showToast('Update folder title error.');
            return;
        }

        cancelEdit();

        setDiagramsResponse(formatDiagramsResponse(response));
    };

    return (
        <h1 className={ `diagrams-folder__title flex items-center text-xl xl:text-3xl ${ mode === DiagramsFolderTitleMode.DEFAULT ? ' border-b mb-2 pb-2 ' : '' }` }>
            {
                mode === DiagramsFolderTitleMode.DEFAULT &&
                <>
                    <button
                        type="button"
                        onClick={ startEdit }
                        className="mr-4 flex items-center text-left flex-1">

                        <span dangerouslySetInnerHTML={{__html: markdown2Html(diagramsFolder.title) }} />

                        {/*<span className="text-sm font-normal ml-4">{ count }</span>*/}
                    </button>

                    <Actions classes="diagrams-folder__actions" gridSize={ 2 }>

                        <DiagramsDeleteFolderButton
                            id={ diagramsFolder.id }
                            title={ diagramsFolder.title }
                        />

                        <EditDiagramsFolderDescriptionBtn
                            id={ diagramsFolder.id }
                        />
                        
                    </Actions>
                </>
            }

            {
                mode === DiagramsFolderTitleMode.EDIT &&
                <TextBox
                    isMultiLine={ false }
                    text={ diagramsFolder.title }
                    cancel={ cancelEdit }
                    save={ save }
                    isLoading={ updateTitleLoading }
                />
            }
        </h1>
    )
};

export default DiagramsFolderTitle;