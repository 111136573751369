import { DiagramIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { ITodo } from '../../../planner-interfaces';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';
import { insertDiagram } from '../../../data/planner-data-service';
import { showToast } from '../../../../../common/domain/toast-provider';
import { combineProjectData } from '../../../domain/planner-provider';
import { useState } from 'react';
import { usePlannerStore } from '../../../data/planner-store';

interface IDiagramBtn {
    todo: ITodo;
}

const DiagramBtn = ({ todo }: IDiagramBtn) => {

    const [loading, setLoading] = useState(false);

    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);

    const onClick = async () => {

        // Insert ----------------------------
        const response = await insertDiagram(
            todo.todo_id,
            '',
        );

        setLoading(false);

        if(!response) { // ?.insertedId
            showToast('Insert diagram error.');
            return;
        }

        setCombinedProject(combineProjectData(response));

        showToast('The new diagram is created.', false);

        /*document.dispatchEvent(new CustomEvent(ADD_NEW_DIAGRAM_EVENT, {
            detail: todo.todo_id,
        }));*/

        sendCloseActionsEvent();
    };

    return (
        <>
            {
                loading &&
                <div className="todo__diagram-btn flex bg-indigo-100 rounded mx-1 justify-center items-center action-btn">
                    <PreloaderIcon size={ 20 } color={ '#717985' } />
                </div>
            }

            {
                !loading &&
                <button
                    type="button"
                    className="todo__diagram-btn flex bg-indigo-100 rounded mx-1 justify-center items-center action-btn"
                    rel="noreferrer"
                    onClick={ onClick }
                    title="Add Diagram">
                    <DiagramIcon color={ '#586180' } />
                </button>
            }
        </>
    )
};

export default DiagramBtn;