import { FlashCardsPerformance, IFlashCard } from '../../flashcards-interfaces';
import { CheckIcon, ClockIcon, ReloadIcon, SunIcon } from '../../../../common/ui/icons';
import StudyBtn from './study-btn';

interface IStudyButtons {
    card: IFlashCard;
    showQuestion: () => void;
    endStudy: () => void;
}

const StudyButtons = ({ card, showQuestion, endStudy }: IStudyButtons) => {

    return (
        <div className="flex items-center flex-col md:flex-row mb-4">

            <StudyBtn
                performance={ FlashCardsPerformance.Again }
                text="Again"
                classes="bg-gray-100 text-gray-800"
                card={ card }
                showQuestion={ showQuestion }
                endStudy={ endStudy }
                icon={
                    <ReloadIcon
                        size={ 20 }
                        color={ '#818181' }
                    />
                }
            />

            <StudyBtn
                performance={ FlashCardsPerformance.Hard }
                text="Hard"
                classes="bg-fuchsia-100 text-fuchsia-800"
                card={ card }
                showQuestion={ showQuestion }
                endStudy={ endStudy }
                icon={
                    <ClockIcon
                        size={ 18 }
                        color={ '#681280' }
                    />
                }
            />

            <StudyBtn
                performance={ FlashCardsPerformance.Good }
                text="Good"
                classes="bg-sky-100 text-sky-800"
                card={ card }
                showQuestion={ showQuestion }
                endStudy={ endStudy }
                icon={
                    <SunIcon
                        size={ 20 }
                        color={ '#15557f' }
                    />
                }
            />

            <StudyBtn
                performance={ FlashCardsPerformance.Easy }
                text="Easy"
                classes="bg-emerald-100 text-emerald-800"
                card={ card }
                showQuestion={ showQuestion }
                endStudy={ endStudy }
                icon={
                    <CheckIcon
                        size={ 20 }
                        color={ '#208075' }
                    />
                }
            />

        </div>
    )
};

export default StudyButtons;