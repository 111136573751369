import { ChangeEvent } from 'react';
import { saveHideTitlesCbToStorage } from '../../../domain/planner-storage';
import { usePlannerStore } from '../../../data/planner-store';

const HideProjectAndSectionTitlesCB = () => {

    const projectHideTitles = usePlannerStore(store => store.projectHideTitles);
    const setProjectHideTitles = usePlannerStore(store => store.setProjectHideTitles);

    const onInput = (evt: ChangeEvent<HTMLInputElement>) => {
        const checked = !(evt.target.checked);
        setProjectHideTitles(checked);
        saveHideTitlesCbToStorage(checked);
    };

    return (
        <label className="flex items-center text-sm ml-4">
            <input
                checked={ projectHideTitles }
                className="mr-2"
                type="checkbox"
                onInput={ onInput }
            />
            <span>Hide Titles</span>
        </label>
    )
};

export default HideProjectAndSectionTitlesCB;