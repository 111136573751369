import { EditIcon } from '../../../../../common/ui/icons';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';
import { DIAGRAMS_FOLDER_DESCRIPTION_EVENT } from '../folder-description';

interface IEditDiagramsFolderDescriptionBtn {
    id: number;
}

const EditDiagramsFolderDescriptionBtn = ({ id }: IEditDiagramsFolderDescriptionBtn) => {

    const handleClick = () => {

        document.dispatchEvent(new CustomEvent(DIAGRAMS_FOLDER_DESCRIPTION_EVENT, {
            detail: id,
        }));

        sendCloseActionsEvent();
    };

    return (
        <button
            onClick={ handleClick }
            className="flex bg-slate-200 rounded mx-1 text-left justify-center items-center whitespace-nowrap action-btn"
            type="button">
            <EditIcon color={ '#6e7781' } size={ 20 } />
        </button>
    )
};

export default EditDiagramsFolderDescriptionBtn;