import { EditIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { ChangeEvent, useCallback, useEffect, useRef, useState, KeyboardEvent as ReactKeyboardEvent } from 'react';
import { handleWiziwig } from '../../../../planner/domain/wiziwig-provider';
import { showToast } from '../../../../../common/domain/toast-provider';
import { useDiagramsStore } from '../../../data/diagrams-store';
import { updateDiagramTitle } from '../../../data/diagrams-data-service';
import { formatDiagramsResponse } from '../../../domain/diagrams-provider';
import Popup from '../../../../../common/ui/popup';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';
import { IDiagram } from '../../../diagrams-interfaces';

enum Mode {
    DEFAULT = 0,
    EDITING = 1,
}

interface IEditDiagramProps {
    diagram: IDiagram;
}

const EditDiagram = ({ diagram }: IEditDiagramProps) => {

    const diagramsResponse = useDiagramsStore(state => state.diagramsResponse);
    const setDiagramsResponse = useDiagramsStore(state => state.setDiagramsResponse);

    const [mode, setMode] = useState(Mode.DEFAULT);
    const [isLoading, setLoading] = useState(false);
    const [title, setTitle] = useState(diagram.title);
    const [titleTouched, setTitleTouched] = useState(false);

    const isTitleValid = !titleTouched || title.trim().length > 0;

    const titleRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        titleRef?.current?.focus();
    }, [mode]);

    useEffect(() => {
        setTitle(diagram.title);
        setTitleTouched(false);
    }, [diagram]);

    const startEditingDiagram = () => {
        setMode(Mode.EDITING);
    };

    const stopEditingDiagram = () => {
        setMode(Mode.DEFAULT);
        sendCloseActionsEvent();
    };

    const onTitleChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setTitle((evt.target as HTMLInputElement).value);
        setTitleTouched(true);
    };

    const save = useCallback(async () => {
        if(title.trim().length <= 0) {
            setTitleTouched(true);
            return;
        }

        setLoading(true);

        const response = await updateDiagramTitle(
            diagram.id,
            title.trim(),
            diagramsResponse?.selectedFolder?.id ?? 0,
        );

        setLoading(false);

        if(!response) {
            showToast('Insert diagram error.');
            return;
        }

        stopEditingDiagram();

        setDiagramsResponse(formatDiagramsResponse(response));
    }, [
        title,
        diagramsResponse?.selectedFolder?.id,
        setDiagramsResponse,
        diagram.id,
    ]);

    useEffect(() => {

        const onKeyDown = async (evt: KeyboardEvent) => {

            if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
                evt.preventDefault();
                await save();
                return;
            }
        };

        document.addEventListener('keydown', onKeyDown, true);

        return () => {
            document.removeEventListener('keydown', onKeyDown, true);
        };

    }, [save]);

    const onKeyDown = async (evt: ReactKeyboardEvent<HTMLInputElement>) => {
        if(handleWiziwig(evt, titleRef, (newText) => {
            setTitle(newText);
            setTitleTouched(true);
        })){
            return;
        }

        if(evt.code === 'Escape') {
            stopEditingDiagram();
        }

        if(evt.code === 'Enter') {
            await save();
        }
    };

    return (
        <>
            {
                mode === Mode.DEFAULT &&
                <button
                    title="Edit Diagram"
                    className="diagram__edit flex bg-slate-300 rounded mx-1 text-left justify-center items-center whitespace-nowrap action-btn"
                    onClick={ startEditingDiagram }
                    type="button">
                    <EditIcon color={ '#6e7781' } size={ 20 } />
                </button>
            }

            {
                mode === Mode.EDITING &&
                <Popup onClose={ stopEditingDiagram } width={ 650 } height={ 250 }>
                    <div className="p-4 flex flex-col overflow-auto text-left font-normal text-gray-900">

                        <h3 className="mt-8 mb-4 text-xl">Edit Diagram</h3>

                        <input
                            ref={ titleRef }
                            onInput={ onTitleChange }
                            onKeyDown={ onKeyDown }
                            className={ `bg-slate-100 text-slate-900 w-full rounded px-4 py-2 outline-none ${ isTitleValid ? '' : 'border-2 border-red-500' }` }
                            type="text"
                            value={ title}
                        />

                        {
                            !isTitleValid && <div className="text-red-500 text-xs mt-1">The title is required.</div>
                        }

                        <div className="flex items-center justify-end text-sm mt-4">
                            <button
                                className="bg-stone-400 text-slate-100 rounded px-4 py-2 mr-2"
                                onClick={ stopEditingDiagram }
                                type="button">Cancel</button>

                            {
                                isLoading &&
                                <PreloaderIcon size={ 24 } color={ '#717985' } />
                            }

                            {
                                !isLoading &&
                                <button
                                    className="bg-slate-500 text-slate-100 rounded px-6 py-2"
                                    onClick={ save }
                                    type="button">Save</button>
                            }
                        </div>
                    </div>
                </Popup>
            }
        </>
    )
};

export default EditDiagram;
