import { IDiagram, IDiagramsFolder, IDiagramsResponse } from '../diagrams-interfaces';
import { getOpenedDiagramFolders } from './diagrams-storage';

export const formatDiagramsResponse = (response: IDiagramsResponse) : IDiagramsResponse|null => {
    if(!response?.isValid) return null;

    const folders = response.folders || [];
    const diagrams = response.diagrams || [];

    const foldersMap = new Map<number, IDiagramsFolder[]>(); // folder_id ---> nested folders
    const rootFolders: IDiagramsFolder[] = [];

    // Collect root folder, and
    // for each folder find its nested folders.
    for(const folder of folders) {
        if(!folder.parent_folder_id) {
            rootFolders.push(folder);
            continue;
        }

        const nested = foldersMap.get(folder.parent_folder_id) || [];
        nested.push(folder);
        foldersMap.set(folder.parent_folder_id, nested);
    }

    const foldersDiagramsMap = new Map<number, IDiagram[]>(); // folder_id ---> diagrams

    for(const diagram of diagrams) {
        const nestedDiagrams = foldersDiagramsMap.get(diagram.folder_id) || [];
        nestedDiagrams.push(diagram);
        foldersDiagramsMap.set(diagram.folder_id, nestedDiagrams);
    }

    const opened = getOpenedDiagramFolders();

    // Set nested folders (if exist) for every folder.
    for(const folder of folders) {
        folder.folders = foldersMap.get(folder.id) || [];
        folder.diagrams = foldersDiagramsMap.get(folder.id) || [];
        folder.isOpened = opened.has(folder.id);
    }

    return {
        selectedFolder: response.selectedFolder,
        folders: rootFolders,
        diagrams,
        isValid: true,
    }
};

const setDiagramsFolderOpenedClosedHelper = (folders: IDiagramsFolder[], openedFolders: Set<number>) => {
    for(const folder of folders) {
        folder.isOpened = openedFolders.has(folder.id);

        if(folder.folders) {
            setDiagramsFolderOpenedClosedHelper(folder.folders, openedFolders);
        }
    }
};

export const setDiagramsFolderOpenedClosed = (diagramsResponse: IDiagramsResponse|null) : IDiagramsResponse|null => {
    if(!diagramsResponse) return null;

    const openedFolders = getOpenedDiagramFolders();

    const copy = { ...diagramsResponse };

    for(const folder of copy.folders) {
        folder.isOpened = openedFolders.has(folder.id);

        if(folder.folders) {
            setDiagramsFolderOpenedClosedHelper(folder.folders, openedFolders);
        }
    }

    return copy;
};

export const getSelectedDiagram = (diagramsResponse: IDiagramsResponse|null, diagramId: number) : IDiagram|null => {
    if(!diagramsResponse) return null;
    return diagramsResponse.diagrams?.find(item => item.id === diagramId) || null;
};