import { NavLink } from 'react-router-dom';
import { toggleMobileMenuBodyClass } from '../../../../../menu/ui/mobile-menu-provider';
import { ArrowDownIcon, ArrowRightIcon } from '../../../../../common/ui/icons';
import { IDiagramsFolder } from '../../../diagrams-interfaces';
import DiagramMenuItem from './diagram-item';
import { closeDiagramFolder, openDiagramFolder } from '../../../domain/diagrams-storage';
import { useDiagramsStore } from '../../../data/diagrams-store';
import { setDiagramsFolderOpenedClosed } from '../../../domain/diagrams-provider';
import Actions from '../../../../../common/ui/actions';
import DiagramsDeleteFolderButton from '../../folder/actions/folder-delete-btn';
import DragDiagramFolderBtn from '../../folder/drag/drag-diagram-folder-btn';
import DragDiagramToFolderTarget from '../../folder/drag/drag-diagram-to-folder-target';
import DragFolderToFolderTarget from '../../folder/drag/drag-folder-to-folder-target';

interface IDiagramsMenuItemFolderProps {
    folder: IDiagramsFolder;
}

const DiagramsMenuItemFolder = ({ folder }: IDiagramsMenuItemFolderProps) => {

    const diagramsResponse = useDiagramsStore(state => state.diagramsResponse);
    const setDiagramsResponse = useDiagramsStore(state => state.setDiagramsResponse);

    const toggleFolder = () => {
        if(folder.isOpened) {
            closeDiagramFolder(folder.id);
        }
        else{
            openDiagramFolder(folder.id);
        }

        setDiagramsResponse(setDiagramsFolderOpenedClosed(diagramsResponse));
    };

    const nestedFoldersCount = folder?.folders?.length ?? 0;
    const nestedDiagramsCount = folder?.diagrams?.length ?? 0;
    const count = nestedFoldersCount + nestedDiagramsCount;

    return (
        <div className="flex flex-col">

            <div className="diagrams-menu-item-box flex items-center border-b border-slate-700 py-3">
                <button
                    onClick={ toggleFolder }
                    type="button">
                    {
                        folder.isOpened &&
                        <ArrowDownIcon
                            classes="mr-3"
                            style={{
                                transform: `translate(0, -1px)`,
                            }}
                            color="#64748B"
                            strokeWidth={ 1.5 }
                            size={ 20 }
                        />
                    }

                    {
                        !folder.isOpened &&
                        <ArrowRightIcon
                            classes="mr-3"
                            color="#64748B"
                            style={{
                                transformOrigin: `11px 11px`,
                            }}
                            strokeWidth={ 1.5 }
                            size={ 20 }
                        />
                    }
                </button>

                <NavLink
                    className={ `flashcard__menu-item flex flex items-center whitespace-pre-wrap` }
                    to={ `/draw-io/f-${ folder.id }` }
                    onClick={ toggleMobileMenuBodyClass }>
                    { folder.title }  ({ count })
                </NavLink>

                <Actions classes="diagram-folder__actions" gridSize={ 2 } mr="mr-3">

                    <DiagramsDeleteFolderButton
                        id={ folder.id }
                        title={ folder.title }
                    />

                    <DragDiagramFolderBtn
                        folder={ folder }
                    />

                </Actions>
            </div>

            <DragFolderToFolderTarget new_parent_folder_id={ folder.id } />
            <DragDiagramToFolderTarget new_parent_folder_id={ folder.id } />

            {
                count > 0 && folder.isOpened &&
                <div className="ml-4">
                    {
                        nestedFoldersCount > 0 &&
                        <div>
                            {
                                folder.folders.map((folder) => {
                                    return (
                                        <DiagramsMenuItemFolder
                                            key={ `folder-${ folder.id }` }
                                            folder={ folder }
                                        />
                                    )
                                })
                            }
                        </div>
                    }

                    {
                        nestedDiagramsCount > 0 &&
                        <div>
                            {
                                folder.diagrams.map((diagram) => {
                                    return (
                                        <DiagramMenuItem
                                            key={ `diagram-${ diagram.id }` }
                                            diagram={ diagram }
                                        />
                                    )
                                })
                            }
                        </div>
                    }
                </div>
            }
        </div>
    )
};

export default DiagramsMenuItemFolder;
