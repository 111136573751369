import { useCallback, useEffect, useRef, useState } from 'react';
import { PreloaderIcon } from '../../../../common/ui/icons';
import { showToast } from '../../../../common/domain/toast-provider';
import { MDXEditor, MDXEditorMethods } from '@mdxeditor/editor';
import { getCompactMarkdownEditorPlugins } from '../../../../common/markdown/markdown-editor-provider';
import { IDiagramsFolder } from '../../diagrams-interfaces';
import { DiagramsFolderDescriptionMode } from './folder-description';
import { updateDiagramFolder } from '../../data/diagrams-data-service';
import { useDiagramsStore } from '../../data/diagrams-store';
import { formatDiagramsResponse } from '../../domain/diagrams-provider';

interface IDiagramsEditFolder {
    folder: IDiagramsFolder;
    setMode: (mode: DiagramsFolderDescriptionMode) => void;
}

const DiagramsEditFolder = ({ folder, setMode }: IDiagramsEditFolder) => {

    const [isLoading, setLoading] = useState(false);

    const setDiagramsResponse = useDiagramsStore(store => store.setDiagramsResponse);

    const editorRef = useRef<MDXEditorMethods>(null);

    useEffect(() => {
        editorRef?.current?.focus();
    }, []);

    useEffect(() => {
        editorRef.current?.setMarkdown(folder.description || '');
    }, [folder.description]);

    const setDefaultMode = useCallback(() => {
        setMode(DiagramsFolderDescriptionMode.DEFAULT);
    }, [setMode]);

    const save = useCallback(async () => {
        const updatedText = editorRef.current?.getMarkdown() || '';

        setLoading(true);
        const response = await updateDiagramFolder(
            folder.id,
            updatedText,
        );
        setLoading(false);

        if(!response) {
            showToast('Update folder error.');
            return;
        }

        setDefaultMode();

        setDiagramsResponse(formatDiagramsResponse(response));
    }, [
        folder.id,
        setDefaultMode,
        setDiagramsResponse,
    ]);

    useEffect(() => {

        const onKeyDown = async (evt: KeyboardEvent) => {

            if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
                evt.preventDefault();
                await save();
                return;
            }

            if(evt.code === 'Escape') {
                evt.stopPropagation();
                editorRef.current?.setMarkdown('');
                return;
            }
        };

        document.addEventListener('keydown', onKeyDown, true);

        return () => {
            document.removeEventListener('keydown', onKeyDown, true);
        };

    }, [save]);

    return (
        <div className={ `flex flex-col` }>

            <label className="text-xs font-bold mt-2 mb-1 text-slate-400">Folder Description</label>
            <div className="markdown-editor border rounded-xl whitespace-pre-wrap text-sm text-slate-800 overflow-auto max-h-[500px] outline-stone-200 mb-4">
                <MDXEditor
                    ref={ editorRef }
                    markdown={ '' }
                    plugins={ getCompactMarkdownEditorPlugins() }
                />
            </div>

            <div className="flex items-center justify-end text-sm mt-4">
                <button
                    className="bg-stone-400 text-slate-100 rounded px-4 py-2 mr-2"
                    onClick={ setDefaultMode }
                    type="button">Cancel</button>

                {
                    isLoading &&
                    <PreloaderIcon size={ 24 } color={ '#717985' } />
                }

                {
                    !isLoading &&
                    <button
                        className="bg-slate-500 text-slate-100 rounded px-6 py-2"
                        onClick={ save }
                        type="button">Save</button>
                }
            </div>
        </div>
    )
};

export default DiagramsEditFolder;