import { DiagramIcon } from '../../../../common/ui/icons';
import { NavLink } from 'react-router-dom';
import { DiagramsView } from '../../diagrams-interfaces';
import { useDiagramsStore } from '../../data/diagrams-store';
import DiagramsMenuItemFolder from './menu-items/folder-item';
import AddFolder from '../folder/actions/add-folder';
import AddDiagram from '../diagram/actions/add-diagram';

const DiagramsMenu = () => {

    const view = useDiagramsStore(state => state.view);
    const setView = useDiagramsStore(state => state.setView);
    const diagramsResponse = useDiagramsStore(state => state.diagramsResponse);

    const setRoot = () => {
        setView(DiagramsView.ROOT);
    };

    return (
        <div className="side-menu flex flex-col xl:w-[250px] bg-slate-800 text-slate-100 leading-7 h-screen color-scheme-dark">
            <div className="overflow-auto p-3 text-sm h-full">

                <div className="pb-3 pt-3 flex items-center text-slate-400">

                    <NavLink
                        className="flex"
                        onClick={ setRoot }
                        to="/draw-io"
                        end={ true }>

                        <span className="mr-3">Diagrams</span>

                        <DiagramIcon
                            size={ 20 }
                            color={ '#28baff' }
                        />
                    </NavLink>
                </div>

               {
                   diagramsResponse?.folders?.map((folder) => {
                        return (
                            <DiagramsMenuItemFolder
                                key={ folder.id }
                                folder={ folder }
                            />
                        )
                    })
               }

               {
                   (view === DiagramsView.ROOT || view === DiagramsView.FolderView) &&
                   <AddFolder />
               }

               {
                    view === DiagramsView.FolderView &&
                    <AddDiagram />
               }

            </div>
        </div>
    )
};

export default DiagramsMenu;