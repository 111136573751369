import { DiagramElement } from '../diagram-element';
import { DiagramElementType } from '../diagram-element-type-enum';

export class DiagramRectangleWithText extends DiagramElement {

    public static readonly defaultText = 'text';
    public static readonly defaultWidth = 120;
    public static readonly defaultHeight = 60;
    public static readonly defaultRadius = 9;
    public static readonly defaultFill = '#ffffff';
    public static readonly defaultStroke = '#000000';
    public static readonly defaultStrokeWidth = 1;
    public static readonly defaultTextColor = '#000000';
    public static readonly defaultTextFontSize = '1rem';

    text: string;
    x: number;
    y: number;
    width: number;
    height: number;
    fill: string;
    stroke: string;
    strokeWidth: number;
    rx?: number;
    ry?: number;
    textColor?: string;
    fontSize?: string;
    isBold?: boolean;
    isItalic?: boolean;
    isUnderline?: boolean;

    constructor(
        id: string,
        text: string,
        x: number,
        y: number,
        width: number,
        height: number,
        fill: string,
        stroke: string,
        strokeWidth: number,
        rx?: number,
        ry?: number,
        textColor?: string,
        fontSize?: string,
        isBold?: boolean,
        isItalic?: boolean,
        isUnderline?: boolean,
    ) {
        super(id, DiagramElementType.RectangleWithText);

        this.text = text;
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
        this.fill = fill;
        this.stroke = stroke;
        this.strokeWidth = strokeWidth;
        this.rx = rx;
        this.ry = ry;
        this.textColor = textColor;
        this.fontSize = fontSize;
        this.isBold = isBold;
        this.isItalic = isItalic;
        this.isUnderline = isUnderline;
    }

    clone() {
        return new DiagramRectangleWithText(
            this.id,
            this.text,
            this.x,
            this.y,
            this.width,
            this.height,
            this.fill,
            this.stroke,
            this.strokeWidth,
            this.rx,
            this.ry,
            this.textColor,
            this.fontSize,
            this.isBold,
            this.isItalic,
            this.isUnderline,
        );
    }

    toSvg(): string {
        return `
            <g data-type="rectangle">
                <rect
                    x="${ this.x }"
                    y="${ this.y }"
                    width="${ this.width }"
                    height="${ this.height }"
                    fill="${ this.fill }"
                    stroke="${ this.stroke }"
                    stroke-width="${ this.strokeWidth ?? DiagramRectangleWithText.defaultStrokeWidth }"
                    rx="${ this.rx }"
                    ry="${ this.ry }"
                />
    
                <foreignObject
                    x="${ this.x }"
                    y="${ this.y }"
                    width="${ this.width }"
                    height="${ this.height }">
                    <div
                        class="flex flex-col items-center justify-center text-center w-full h-full p-2"
                        style="color: ${ this.textColor || DiagramRectangleWithText.defaultTextColor }; 
                               font-size: ${ this.fontSize || DiagramRectangleWithText.defaultTextFontSize };
                               ${ this.isBold ? 'font-weight: bold;' : '' }
                               ${ this.isItalic ? 'font-style: italic;' : '' }
                               ${ this.isUnderline ? 'text-decoration: underline;' : '' }">
                        ${ this.text }
                    </div>
                </foreignObject>
            </g>
        `;
    }

    public static fromJson(json: DiagramRectangleWithText): DiagramElement {
        return new DiagramRectangleWithText(
            json.id,
            json.text,
            json.x,
            json.y,
            json.width,
            json.height,
            json.fill,
            json.stroke,
            json.strokeWidth,
            json.rx,
            json.ry,
            json.textColor,
            json.fontSize,
            json.isBold,
            json.isItalic,
            json.isUnderline,
        );
    }
}