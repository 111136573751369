import LineSelectionCircle, { LineSelectionCirclePointType } from './line-selection-circle';
import { DiagramLine } from '../../../../domain/diagram-elements/elements/diagram-line';
import { DiagramArrow } from '../../../../domain/diagram-elements/elements/diagram-arrow';
import { TodoDiagramUseStore } from '../../../../data/store';

interface ILineSelectionProps {
    useStore: TodoDiagramUseStore;
    shape: DiagramLine|DiagramArrow;
}

const LineSelection = ({ shape, useStore }: ILineSelectionProps) => {
    return (
        <g>
            <LineSelectionCircle
                shape={ shape }
                cx={ shape.x1 }
                cy={ shape.y1 }
                type={ LineSelectionCirclePointType.Start }
                useStore={ useStore }
            />

            <LineSelectionCircle
                shape={ shape }
                cx={ shape.x2 }
                cy={ shape.y2 }
                type={ LineSelectionCirclePointType.End }
                useStore={ useStore }
            />
        </g>
    )
};

export default LineSelection;