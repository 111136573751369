import {
    mapToSvgCoordinates,
    SELECTION_CIRCLE_COLOR,
    SELECTION_CIRCLE_RADIUS, updateHistory
} from '../../../../domain/todo-diagram-provider';
import { PointerEvent as ReactPointerEvent, MouseEvent } from 'react';
import { TodoDiagramUseStore } from '../../../../data/store';
import { DiagramLine } from '../../../../domain/diagram-elements/elements/diagram-line';
import { DiagramArrow } from '../../../../domain/diagram-elements/elements/diagram-arrow';

export enum LineSelectionCirclePointType {
    Start = 0,
    End = 1,
}

interface ILineSelectionCircleProps {
    type: LineSelectionCirclePointType;
    cx: number;
    cy: number;
    useStore: TodoDiagramUseStore;
    shape: DiagramLine|DiagramArrow;
}

const LineSelectionCircle = ({ type, cx, cy, useStore, shape }: ILineSelectionCircleProps) => {

    const data = useStore(state => state.data);
    const setData = useStore(state => state.setData);
    const foundIndex = data?.children?.findIndex(item => item.id === shape.id);
    const dataStack = useStore(state => state.dataStack);
    const setDataStack = useStore(state => state.setDataStack);

    const onClick = (evt: MouseEvent) => {
        evt.stopPropagation();
    };

    const onCircleDrag = (moveEvent: PointerEvent) => {
        if(foundIndex === -1) return;

        let svg: SVGSVGElement|null = null;

        if (moveEvent.target instanceof SVGSVGElement) {
            svg = moveEvent.target;
        }
        else{
            if(moveEvent.target instanceof SVGElement) {
                svg = moveEvent.target?.closest('svg') as SVGSVGElement;
            }
        }

        if(!svg) return;

        const updatedShape = shape.clone() as DiagramLine|DiagramArrow;

        // console.log(document.elementsFromPoint(moveEvent.pageX, moveEvent.pageY))

        const { x: newX, y: newY } = mapToSvgCoordinates(svg, moveEvent.clientX, moveEvent.clientY);

        switch (type) {
            case LineSelectionCirclePointType.Start: {
                updatedShape.x1 = newX;
                updatedShape.y1 = newY;
                break;
            }
            case LineSelectionCirclePointType.End: {
                updatedShape.x2 = newX;
                updatedShape.y2 = newY;
                break;
            }
        }

        const copy = data.clone();
        copy.children[foundIndex] = updatedShape;

        setData(copy);
    };

    const onPointerDown = (evt: ReactPointerEvent<SVGCircleElement>) => {
        evt.preventDefault();

        const onPointerMove = (moveEvent: PointerEvent) => {
            onCircleDrag(moveEvent);
        };

        const onPointerUp = () => {
            setDataStack(updateHistory(dataStack, data));
            window.removeEventListener('pointermove', onPointerMove);
            window.removeEventListener('pointerup', onPointerUp, false);
        };

        window.addEventListener('pointermove', onPointerMove);
        window.addEventListener('pointerup', onPointerUp, false);
    };

    return (
        <circle
            className="cursor-pointer"
            cx={ cx }
            cy={ cy }
            r={ SELECTION_CIRCLE_RADIUS }
            fill={ SELECTION_CIRCLE_COLOR }
            onPointerDown={ onPointerDown }
            onClick={ onClick }
        />
    )
};

export default LineSelectionCircle;