import { PointerEvent as ReactPointerEvent, MouseEvent } from 'react';
import { TodoDiagramUseStore } from '../../data/store';
import {
    mapToSvgCoordinates,
    SVG_SELECTION_COLOR,
    SVG_SELECTION_SIZE,
    tryGetSvgElement,
    updateHistory
} from '../../domain/todo-diagram-provider';

interface ISvgSelectionPointProps {
    index: number;
    x: number;
    y: number;
    cursor: string;
    useStore: TodoDiagramUseStore;
}

const SvgSelectionPoint = ({ index, x, y, cursor, useStore }: ISvgSelectionPointProps) => {

    const data = useStore(state => state.data);
    const setData = useStore(state => state.setData);
    const dataStack = useStore(state => state.dataStack);
    const setDataStack = useStore(state => state.setDataStack);

    const onClick = (evt: MouseEvent) => {
        evt.stopPropagation();
    };

    const onDrag = (moveEvent: PointerEvent) => {

        const $svg = tryGetSvgElement(moveEvent.target);
        if(!$svg) return;

        const { x: newX, y: newY } = mapToSvgCoordinates($svg, moveEvent.clientX, moveEvent.clientY);


        const copy = data.clone();

        switch (index) {
            case 0: {
                // Top-left
                const diffX = data.x - newX;
                copy.width += diffX;
                break;
            }

            case 1: {
                // Top-right
                const diffX = data.x + data.width - newX;
                copy.width -= diffX;
                break;
            }

            case 2: {
                // Bottom-left
                const diffX = data.x - newX;
                copy.width += diffX;

                const diffY = data.y + data.height - newY;
                copy.height -= diffY;
                break;
            }

            case 3: {
                // Bottom-right
                const diffX = data.x + data.width - newX;
                copy.width -= diffX;

                const diffY = data.y + data.height - newY;
                copy.height -= diffY;
                break;
            }
        }

        setData(copy);
    };

    const onPointerDown = (evt: ReactPointerEvent<SVGRectElement>) => {
        evt.preventDefault();

        // @ts-ignore
        evt.target.setPointerCapture(evt.pointerId);

        const onPointerMove = (moveEvent: PointerEvent) => {
            onDrag(moveEvent);
        };

        const onPointerUp = () => {
            // @ts-ignore
            evt.target.releasePointerCapture(evt.pointerId);

            setDataStack(updateHistory(dataStack, data));
            window.removeEventListener('pointermove', onPointerMove);
            window.removeEventListener('pointerup', onPointerUp);
        };

        window.addEventListener('pointermove', onPointerMove);
        window.addEventListener('pointerup', onPointerUp);
    };

    return (
        <rect
            style={{
                cursor,
            }}
            x={ x }
            y={ y }
            width={ SVG_SELECTION_SIZE }
            height={ SVG_SELECTION_SIZE }
            fill={ SVG_SELECTION_COLOR }
            onPointerDown={ onPointerDown }
            onClick={ onClick }
        />
    )
};

export default SvgSelectionPoint;