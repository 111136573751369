import { TodoDiagramUseStore } from '../../../data/store';
import { DiagramElementType } from '../../../domain/diagram-elements/diagram-element-type-enum';
import { DiagramRectangleWithText } from '../../../domain/diagram-elements/elements/diagram-rectangle-with-text';
import { ChangeEvent } from 'react';
import { updateHistory } from '../../../domain/todo-diagram-provider';

interface IBgColorPicker {
    useStore: TodoDiagramUseStore;
}

const BgColorPicker = ({ useStore }: IBgColorPicker) => {

    const selectedElement = useStore(state => state.selectedElement);
    const data = useStore(state => state.data);
    const setData = useStore(state => state.setData);
    const foundIndex = data?.children?.findIndex(item => item.id === selectedElement?.id);
    const dataStack = useStore(state => state.dataStack);
    const setDataStack = useStore(state => state.setDataStack);

    const onChange = (evt: ChangeEvent<HTMLInputElement>) => {

        if(foundIndex === -1) return;

        const copy = data.clone();

        const rect = selectedElement as DiagramRectangleWithText;
        rect.fill = evt.target.value;
        copy.children[foundIndex] = rect;

        setData(copy);
        setDataStack(updateHistory(dataStack, copy));
    };

    return (
        <>
            {
                selectedElement &&
                (selectedElement.type === DiagramElementType.RectangleWithText ||
                selectedElement.type === DiagramElementType.RoundedRectangleWithText) &&
                <label className="flex items-center text-slate-500 text-xs bg-white px-2 py-1 border rounded mr-4">
                    <span className="mr-2">BG</span>
                    <input
                        type="color"
                        className="w-4 h-4"
                        value={ (selectedElement as DiagramRectangleWithText).fill }
                        onChange={ onChange }
                    />
                </label>
            }
        </>
    )
};

export default BgColorPicker;