import { ITodo, ITodoDiagram } from '../planner/planner-interfaces';
import { createTodoDiagramStore } from './data/store';
import TodoDiagram from './ui/todo-diagram';

interface ITodoDiagramEntryPointProps {
    todo: ITodo;
    todoDiagram: ITodoDiagram|null;
    onClose: () => void;

    // Alternative: handling the case, when we don't have specific _todo.
    save?: (json: string) => Promise<void>;
    outerJson?: string;
}

const TodoDiagramEntryPoint = ({ todo, todoDiagram, onClose, save, outerJson }: ITodoDiagramEntryPointProps) => {

    const useStore = createTodoDiagramStore(todo, todoDiagram, onClose, save, outerJson);

    return (
        <TodoDiagram useStore={ useStore } />
    )
};

export default TodoDiagramEntryPoint;