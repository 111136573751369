import { get, deleteJson, postJson, putJson } from '../../../common/data/common-data-service';
import { IDiagramsResponse } from '../diagrams-interfaces';

// --------------- FOLDER -----------------------

export const getDiagramsData = async (folder_id?: number) : Promise<IDiagramsResponse> => {
    return await get(`/api/diagrams/diagram/get-data${ folder_id ? `?folder_id=${ folder_id }` : '' }`);
};

export const insertDiagramFolder = async (
    title: string,
    description: string,
    parent_folder_id?: number
) : Promise<IDiagramsResponse> => {
    return await postJson(`/api/diagrams/folder/insert`, {
        title,
        description,
        parent_folder_id,
    });
};

export const deleteDiagramFolder = async (id: number) : Promise<IDiagramsResponse> => {
    return await deleteJson(`/api/diagrams/folder/delete`, {
        id,
    });
};

export const updateDiagramFolder = async (id: number, description: string) : Promise<IDiagramsResponse> => {
    return await putJson(`/api/diagrams/folder/update`, {
        id,
        description,
    });
};

export const dragDiagramFolder = async (new_parent_folder_id: number, moving_folder_id: number) : Promise<IDiagramsResponse> => {
    return await putJson(`/api/diagrams/folder/drag`, {
        new_parent_folder_id,
        moving_folder_id,
    });
};

export const updateDiagramFolderTitle = async (id: number, title: string) : Promise<IDiagramsResponse> => {
    return await putJson(`/api/diagrams/folder/update-title`, {
        id,
        title,
    });
};

// --------------- DIAGRAMS -----------------------

export const insertDiagram = async (
    title: string,
    description: string,
    folder_id: number
) : Promise<IDiagramsResponse> => {
    return await postJson(`/api/diagrams/diagram/insert`, {
        title,
        description,
        folder_id,
    });
};

export const duplicateDiagram = async (initial_diagram_id: number) : Promise<IDiagramsResponse> => {
    return await postJson(`/api/diagrams/diagram/duplicate`, {
        initial_diagram_id,
    });
};


export const deleteDiagram = async (id: number) : Promise<IDiagramsResponse> => {
    return await deleteJson(`/api/diagrams/diagram/delete`, {
        id,
    });
};

export const updateDiagram = async (
    id: number,
    title: string,
    description: string,
    selected_folder_id: number
) : Promise<IDiagramsResponse> => {
    return await putJson(`/api/diagrams/diagram/update`, {
        id,
        title,
        description,
        selected_folder_id,
    });
};

export const updateDiagramTitle = async (
    id: number,
    title: string,
    selected_folder_id: number
) : Promise<IDiagramsResponse> => {
    return await putJson(`/api/diagrams/diagram/update-title`, {
        id,
        title,
        selected_folder_id,
    });
};

export const dragDiagramToFolder = async (new_parent_folder_id: number, moving_diagram_id: number) : Promise<IDiagramsResponse> => {
    return await putJson(`/api/diagrams/diagram/drag`, {
        new_parent_folder_id,
        moving_diagram_id,
    });
};

export const updateDiagramSvg = async (
    id: number,
    svg: string,
    selected_folder_id: number
) : Promise<IDiagramsResponse> => {
    return await putJson(`/api/diagrams/diagram/update-svg`, {
        id,
        svg,
        selected_folder_id,
    });
};