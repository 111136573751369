import { DragEvent } from 'react';
import { dragTodo } from '../../../data/planner-data-service';
import { showToast } from '../../../../../common/domain/toast-provider';
import { combineProjectData } from '../../../domain/planner-provider';
import { usePlannerStore } from '../../../data/planner-store';

interface ITodoDragTargetProps {
    targetProjectId: number;
    targetTodoId: number|string;
    targetOrder: number;
    isBefore: boolean;
    classes?: string;

    // When there is an empty section without todos
    isFirstSectionTodo?: boolean;
    section_id?: number;

    // When target doesn't have nested tasks,
    // and we want to move the source to be its first
    // nested task.
    targetAsParent: boolean;
}

const CLASS_NAME = 'todo__drag-target__over';

const TodoDragTarget = (props: ITodoDragTargetProps) => {

    const { targetTodoId, targetOrder, isBefore, targetProjectId, targetAsParent } = props;

    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);

    const targetOnDragOver = (evt: DragEvent<HTMLDivElement>) => {
        evt.preventDefault();
    };

    const targetOnDragEnter = (evt: DragEvent<HTMLDivElement>) => {
        evt.currentTarget.classList.add(CLASS_NAME);
    };

    const targetOnDragLeave = (evt: DragEvent<HTMLDivElement>) => {
        evt.currentTarget.classList.remove(CLASS_NAME);
    };

    /**
     * https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer/setData
     */
    const targetOnDrop = async (evt: DragEvent<HTMLDivElement>) => {
        evt.preventDefault();
        const data = evt.dataTransfer.getData('text');

        if(data === null ||
            data === undefined ||
            data.trim() === '') {
            showToast('Drop task error.');
            return;
        }

        const sourceTodoId = data;
        console.log(`${ sourceTodoId } ---> ${ targetTodoId } (order = ${ targetOrder }, isBefore = ${ isBefore })`);

        if(sourceTodoId === targetTodoId) {
            showToast('The source and the target should be different.');
            return;
        }

        const response = await dragTodo(
            targetProjectId,
            sourceTodoId,
            targetTodoId,
            isBefore,
            props.isFirstSectionTodo ?? false,
            props.section_id ?? 0,
            targetAsParent,
        );

        if(!response) {
            showToast('Drag task error.');
            return;
        }

        setCombinedProject(combineProjectData(response));
    };

    return (
        <div
            onDragOver={ targetOnDragOver }
            onDragEnter={ targetOnDragEnter }
            onDragLeave={ targetOnDragLeave }
            onDrop={ targetOnDrop }
            className={ `todo__drag-target w-full h-[5px] bg-slate-300 rounded mt-1 hidden ${ props.classes ?? '' }` }
        />
    )
};

export default TodoDragTarget;