import { ArrowLeftIcon, ArrowRightIcon, CalendarIcon } from '../../../../common/ui/icons';
import SectionTodos from '../section/section-todos';
import { INDEX_PROJECT_ID } from '../../domain/planner-provider';
import ProjectView from '../projects/project-view';
import ActiveTodos from '../active-todos/active-todos';
import { useNavigate, useParams } from 'react-router-dom';
import { addDays, format, parse, isToday, isTomorrow, isYesterday } from 'date-fns';
import { useEffect } from 'react';
import HideProjectAndSectionTitlesCB from '../projects/actions/project-hide-titles-cb';
import { usePlannerStore } from '../../data/planner-store';
import ProjectShowDescriptionsCB from '../projects/actions/project-show-desc-cb';

const getDateTitle = (date: Date) => {
    const weekDayName = format(date, 'EEEE');
    const formatted = `${ format(date, 'dd MMM, yyyy') } - ${ weekDayName }`;

    if(isToday(date)) {
        return <span className="flex items-center">Today <span className="text-xs md:text-xl text-violet-800 mx-4">{ formatted }</span></span>;
    }

    if(isYesterday(date)) {
        return <span className="flex items-center">Yesterday <span className="text-xs md:text-xl text-violet-800 mx-4">{ formatted }</span></span>;
    }

    if(isTomorrow(date)) {
        return <span className="flex items-center">Tomorrow <span className="text-xs md:text-xl text-violet-800 mx-4">{ formatted }</span></span>;
    }

    return <span className="flex items-center">{ formatted }</span>;
};

const DayView = () => {

    const navigate = useNavigate();
    const { project_id: project_id_url } = useParams();

    const combinedProject = usePlannerStore(state => state.combinedProject);
    const totalTodosCount = combinedProject?.projects?.reduce((acc, curr) => curr.todosCount + acc, 0);
    const date = parse(project_id_url.toString().replace('day-', ''), 'dd-MM-yyyy', new Date());

    const changeToPrevDay = () => {
        const prev = addDays(date, -1);
        navigate(`/planner/day-${ format(prev, 'dd-MM-yyyy') }`);
    };

    const changeToNextDay = () => {
        const next = addDays(date, 1);
        navigate(`/planner/day-${ format(next, 'dd-MM-yyyy') }`);
    };

    useEffect(() => {
        document.title = `${ format(date, 'dd MMM, yyyy') } (${ totalTodosCount ?? 0 }) | Productivity`;
    }, [totalTodosCount, date]);

    return (
        <div className="xl:w-[800px] max-w-full mx-auto">
            <div className="mb-4 w-full flex flex-col">

                <h1 className="text-xl xl:text-3xl border-b border-teal-400 pb-2 flex items-center">
                    <CalendarIcon classes="mr-4" size={ 35 } color={ '#2E766E' } />
                    { getDateTitle(date) }

                    <span className="text-sm font-normal mx-4">{ totalTodosCount }</span>

                    <div className="flex items-center ml-auto">
                        <button type="button" onClick={ changeToPrevDay }>
                            <ArrowLeftIcon />
                        </button>

                        <button type="button" onClick={ changeToNextDay }>
                            <ArrowRightIcon />
                        </button>
                    </div>

                </h1>

                {
                    /**
                     * Optional project description here.
                     */
                }
            </div>

            <ActiveTodos
                activeTodos={ combinedProject?.activeTodos || [] }
            />

            <div className="flex justify-end">
                <HideProjectAndSectionTitlesCB />
                <ProjectShowDescriptionsCB />
            </div>

            <div className="mb-10">
                <SectionTodos
                    combinedSection={ {
                        section: {
                            section_id: 0,
                            section_title: '',
                            section_order: 0,
                            section_project_id: INDEX_PROJECT_ID,
                        },
                        todos: [],
                        isOpened: true,
                    } }
                    project_id={ INDEX_PROJECT_ID }
                    titleVisible={ false }
                />
            </div>

            {
                combinedProject?.projects.map(project => {

                    return (
                        <ProjectView
                            key={`project-${ project.project_id }`}
                            project={ project}
                        />
                    )
                })
            }

        </div>
    )
};

export default DayView;