import { DeleteIcon } from '../../../../../common/ui/icons';
import { TodoDiagramUseStore } from '../../../data/store';
import { useCallback, useEffect } from 'react';
import { updateHistory } from '../../../domain/todo-diagram-provider';

export interface IDeleteSelectedElementBtn {
    useStore: TodoDiagramUseStore;
}

const DeleteSelectedElementBtn = ({ useStore }: IDeleteSelectedElementBtn) => {

    const data = useStore(state => state.data);
    const setData = useStore(state => state.setData);
    const selectedElement = useStore(state => state.selectedElement);
    const setSelectedElement = useStore(state => state.setSelectedElement);
    const dataStack = useStore(state => state.dataStack);
    const setDataStack = useStore(state => state.setDataStack);

    const onClick = useCallback(() => {
        const foundIndex = data?.children?.findIndex(item => item.id === selectedElement?.id);
        if(foundIndex === -1) return;

        const copy = data.clone();
        copy.children.splice(foundIndex, 1);
        setData(copy);
        setDataStack(updateHistory(dataStack, copy));

        setSelectedElement(null);
    }, [
        data,
        selectedElement?.id,
        setData,
        setSelectedElement,
        dataStack,
        setDataStack,
    ]);

    useEffect(() => {
        const handleGlobalKeyDown = (evt: KeyboardEvent) => {

            if((evt.code === 'Delete' || evt.code === 'Backspace') && selectedElement) {

                const element = evt.target as HTMLElement;
                if(element.getAttribute('contenteditable')) return;

                onClick();
                evt.preventDefault();
                return;
            }
        };

        document.addEventListener('keydown', handleGlobalKeyDown);

        return () => {
            document.removeEventListener('keydown', handleGlobalKeyDown);
        };
    }, [ onClick, selectedElement ]);

    return (
        <>
            {
                selectedElement &&
                <button
                    className="ml-4"
                    title="Delete Selected Element"
                    type="button"
                    onClick={ onClick }>
                    <DeleteIcon color={ '#BE185D' } />
                </button>
            }
        </>
    )
};

export default DeleteSelectedElementBtn;