import { DiagramRectangleWithText } from './diagram-rectangle-with-text';
import { DiagramElement } from '../diagram-element';

export class DiagramText extends DiagramRectangleWithText {

    constructor(
        id: string,
        text: string,
        x: number,
        y: number,
        width: number,
        height: number,
        textColor?: string,
        fontSize?: string,
        isBold?: boolean,
        isItalic?: boolean,
        isUnderline?: boolean,
    ) {
        super(
            id,
            text,
            x,
            y,
            width,
            height,
            'transparent',
            'none',
            0,
            0,
            0,
            textColor,
            fontSize,
            isBold,
            isItalic,
            isUnderline,
        );
    }

    clone() {
        return new DiagramText(
            this.id,
            this.text,
            this.x,
            this.y,
            this.width,
            this.height,
            this.textColor,
            this.fontSize,
            this.isBold,
            this.isItalic,
            this.isUnderline
        );
    }

    public static fromJson(json: DiagramRectangleWithText): DiagramElement {
        return new DiagramText(
            json.id,
            json.text,
            json.x,
            json.y,
            json.width,
            json.height,
            json.textColor,
            json.fontSize,
            json.isBold,
            json.isItalic,
            json.isUnderline,
        );
    }
}