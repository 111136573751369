import { DragEvent } from 'react';
import { showToast } from '../../../../../common/domain/toast-provider';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';
import { useDiagramsStore } from '../../../data/diagrams-store';
import { formatDiagramsResponse } from '../../../domain/diagrams-provider';
import { dragDiagramToFolder } from '../../../data/diagrams-data-service';

interface IDragDiagramToFolderTargetProps {
    new_parent_folder_id: number;
}

const DRAG_CLASS = 'diagrams__drag-diagram-to-folder-target__over';

const DragDiagramToFolderTarget = ({ new_parent_folder_id }: IDragDiagramToFolderTargetProps) => {

    const setDiagramsResponse = useDiagramsStore(state => state.setDiagramsResponse);

    const targetOnDragOver = (evt: DragEvent<HTMLDivElement>) => {
        evt.preventDefault();
    };

    const targetOnDragEnter = (evt: DragEvent<HTMLDivElement>) => {
        evt.currentTarget.classList.add(DRAG_CLASS);
    };

    const targetOnDragLeave = (evt: DragEvent<HTMLDivElement>) => {
        evt.currentTarget.classList.remove(DRAG_CLASS);
    };

    /**
     * https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer/setData
     */
    const targetOnDrop = async (evt: DragEvent<HTMLDivElement>) => {
        evt.preventDefault();
        const data = evt.dataTransfer.getData('text');

        if(data === null ||
            data === undefined ||
            data.trim() === '') {
            showToast('Drop diagram error.');
            return;
        }

        const moving_diagram_id = Number(data);

        if(isNaN(Number(moving_diagram_id))) {
            showToast('Drop diagram error.');
            return;
        }

        const response = await dragDiagramToFolder(
            new_parent_folder_id,
            moving_diagram_id,
        );

        if(!response) {
            showToast('Drag diagram error.');
            return;
        }

        setDiagramsResponse(formatDiagramsResponse(response));
        sendCloseActionsEvent();
    };

    return (
        <div
            onDragOver={ targetOnDragOver }
            onDragEnter={ targetOnDragEnter }
            onDragLeave={ targetOnDragLeave }
            onDrop={ targetOnDrop }
            className="diagrams__drag-diagram-to-folder-target h-[5px] bg-slate-500 rounded my-1 hidden ml-2"
        />
    )
};

export default DragDiagramToFolderTarget;
