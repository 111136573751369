import { useState } from 'react';
import { CopyIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { showToast } from '../../../../../common/domain/toast-provider';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';
import { IDiagram } from '../../../diagrams-interfaces';
import { duplicateDiagram } from '../../../data/diagrams-data-service';
import { formatDiagramsResponse, setDiagramsFolderOpenedClosed } from '../../../domain/diagrams-provider';
import { useDiagramsStore } from '../../../data/diagrams-store';

interface IDuplicateDiagramButtonProps {
    diagram: IDiagram;
}

const DuplicateDiagramButton = ({ diagram }: IDuplicateDiagramButtonProps) => {

    const [isLoading, setLoading] = useState(false);

    const setDiagramsResponse = useDiagramsStore(state => state.setDiagramsResponse);

    const duplicateHandler = async () => {

        setLoading(true);
        const response = await duplicateDiagram(
            diagram.id,
        );
        setLoading(false);

        if(!response) {
            showToast('Duplicate diagram error.');
            return;
        }

        setDiagramsResponse(setDiagramsFolderOpenedClosed(formatDiagramsResponse(response)));

        sendCloseActionsEvent();
    };

    return (
        <>
            {
                isLoading &&
                <PreloaderIcon size={ 20 } color={ '#717985' } />
            }

            {
                !isLoading &&
                <button
                    title="Duplicate Diagram"
                    className="diagram__duplicate flex bg-green-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
                    onClick={ duplicateHandler }
                    type="button">
                    <CopyIcon
                        size={ 20 }
                        color={ '#128573' }
                        strokeWidth={ 1 }
                    />
                </button>
            }
        </>
    )
};

export default DuplicateDiagramButton;