import { TodoDiagramUseStore } from '../data/store';
import Toolbar from './toolbar/toolbar';
import Scene from './scene';
import { MouseEvent as ReactMouseEvent } from 'react';

interface ITodoDiagramProps {
    useStore: TodoDiagramUseStore;
}

const TodoDiagram = ({ useStore }: ITodoDiagramProps) => {

    const onClick = (evt: ReactMouseEvent) => {
        evt.stopPropagation();
    };

    return (
        <div onClick={ onClick }>
            <Toolbar useStore={ useStore } />
            <Scene useStore={ useStore } />
        </div>
    )
};

export default TodoDiagram;