import TextBox from '../../../../../common/ui/forms/text-box';
import { ITodoDiagram } from '../../../planner-interfaces';
import { useState, MouseEvent } from 'react';
import { updateDiagramDescription } from '../../../data/planner-data-service';
import { showToast } from '../../../../../common/domain/toast-provider';
import { combineProjectData } from '../../../domain/planner-provider';
import { usePlannerStore } from '../../../data/planner-store';

interface ITodoDiagramEditProps {
    diagram: ITodoDiagram;
    stopEditDescription: () => void;
}

const TodoDiagramEditDescription = ({ diagram, stopEditDescription }: ITodoDiagramEditProps) => {

    const [loading, setLoading] = useState(false);

    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);

    const save = async (updatedText: string) => {
        setLoading(true);
        const response = await updateDiagramDescription(diagram.diagram_id, updatedText.trim());
        setLoading(false);

        if(!response?.isValid) {
            showToast('Update diagram description error.');
            return;
        }

        stopEditDescription();

        setCombinedProject(combineProjectData(response));
    };

    const stopPropagation = (evt: MouseEvent) => {
        evt.stopPropagation();
    };

    return (
        <div className="flex-1" onClick={ stopPropagation }>
            <TextBox
                isMultiLine={ false }
                text={ diagram.diagram_desc }
                cancel={ stopEditDescription }
                save={ save }
                isLoading={ loading }
            />
        </div>
    )
};

export default TodoDiagramEditDescription;