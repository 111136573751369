import { ChangeEvent } from 'react';
import { saveShowDescriptionsCbToStorage } from '../../../domain/planner-storage';
import { usePlannerStore } from '../../../data/planner-store';

const ProjectShowDescriptionsCB = () => {

    const projectShowDescriptions = usePlannerStore(store => store.projectShowDescriptions);
    const setProjectShowDescriptions = usePlannerStore(store => store.setProjectShowDescriptions);

    const onInput = (evt: ChangeEvent<HTMLInputElement>) => {
        const checked = !(evt.target.checked);
        setProjectShowDescriptions(checked);
        saveShowDescriptionsCbToStorage(checked);
    };

    return (
        <label className="flex items-center text-sm ml-4">
            <input
                checked={ projectShowDescriptions }
                className="mr-2"
                type="checkbox"
                onInput={ onInput }
            />
            <span>Show Descriptions</span>
        </label>
    )
};

export default ProjectShowDescriptionsCB;