import { DiagramElementType } from '../diagram-element-type-enum';
import { DiagramElement } from '../diagram-element';

export class DiagramArrow extends DiagramElement{
    public static readonly defaultSize = 50;
    public static readonly arrowHeadSize = 10;

    x1: number;
    y1: number;
    x2: number;
    y2: number;
    arrowheadSize: number;
    stroke?: string;
    strokeWidth?: number;

    constructor(
        id: string,
        x1: number,
        y1: number,
        x2: number,
        y2: number,
        arrowheadSize: number,
        stroke?: string,
        strokeWidth?: number,
    ) {
        super(id, DiagramElementType.Arrow);

        this.x1 = x1;
        this.y1 = y1;
        this.x2 = x2;
        this.y2 = y2;
        this.arrowheadSize = arrowheadSize;
        this.stroke = stroke;
        this.strokeWidth = strokeWidth;
    }

    clone() {
        return new DiagramArrow(
            this.id,
            this.x1,
            this.y1,
            this.x2,
            this.y2,
            this.arrowheadSize,
            this.stroke,
            this.strokeWidth,
        )
    }

    toSvg(): string {

        const arrowHeadId = `arrow-head-${ this.id }`;

        return `
            <g data-type="arrow">
                <defs>
                    <marker
                        id="${ arrowHeadId }"
                        markerWidth="${ this.arrowheadSize }"
                        markerHeight="${ this.arrowheadSize }"
                        refX="${ this.arrowheadSize }"
                        refY="${ this.arrowheadSize / 2 }"
                        orient="auto"
                        markerUnits="userSpaceOnUse">
                        <path
                            d="M 0 0 L ${ this.arrowheadSize } ${ this.arrowheadSize / 2 } L 0 ${ this.arrowheadSize }"
                            fill="none"
                            stroke="${ this.stroke }"
                            stroke-width="${ this.strokeWidth }"
                            stroke-linejoin="round"
                            stroke-linecap="round"
                        />
                    </marker>
                </defs>
              
                <line
                    x1="${ this.x1 }"
                    y1="${ this.y1 }"
                    x2="${ this.x2 }"
                    y2="${ this.y2 }"
                    stroke="${ this.stroke }"
                    stroke-width="${ this.strokeWidth }"
                    marker-end="url(#${ arrowHeadId })"
                />
            </g>
        `;
    }

    public static fromJson(json: DiagramArrow): DiagramElement {
        return new DiagramArrow(
            json.id,
            json.x1,
            json.y1,
            json.x2,
            json.y2,
            json.arrowheadSize,
            json.stroke,
            json.strokeWidth,
        );
    }
}