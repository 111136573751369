import { PreloaderIcon, SaveIcon } from '../../../../../common/ui/icons';
import { TodoDiagramUseStore } from '../../../data/store';
import { insertDiagram, updateDiagramJson } from '../../../../planner/data/planner-data-service';
import { showToast } from '../../../../../common/domain/toast-provider';
import { combineProjectData } from '../../../../planner/domain/planner-provider';
import { usePlannerStore } from '../../../../planner/data/planner-store';
import { useCallback, useEffect, useState } from 'react';
import { ITodoDiagram } from '../../../../planner/planner-interfaces';
import { duplicateElement, handleArrowKeys, updateHistory } from '../../../domain/todo-diagram-provider';
import { DiagramSvg } from '../../../domain/diagram-elements/diagram-svg';

export interface ISaveDiagramBtnProps {
    useStore: TodoDiagramUseStore;
}

const SaveDiagramBtn = ({ useStore }: ISaveDiagramBtnProps) => {

    const [isLoading, setLoading] = useState(false);

    const data = useStore(state => state.data);
    const setData = useStore(state => state.setData);
    const todo = useStore(state => state.todo);
    const todoDiagram = useStore(state => state.todoDiagram);
    const setTodoDiagram = useStore(state => state.setTodoDiagram);
    const selectedElement = useStore(state => state.selectedElement);
    const setSelectedElement = useStore(state => state.setSelectedElement);
    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);
    const dataStack = useStore(state => state.dataStack);
    const setDataStack = useStore(state => state.setDataStack);
    const copiedElement = useStore(state => state.copiedElement);
    const setCopiedElement = useStore(state => state.setCopiedElement);
    const save = useStore(state => state.save);

    const onClick = useCallback(async () => {
        if(save) {
            // Alternative: handling the case, when we don't have specific _todo.
            setLoading(true);
            await save(JSON.stringify(data));
            setLoading(false);
            return;
        }

        setLoading(true);

        if(todoDiagram) {
            // Update ----------------------------
            const diagram_id = todoDiagram.diagram_id;

            const response = await updateDiagramJson(
                todoDiagram.diagram_id,
                JSON.stringify(data),
            );

            setLoading(false);

            if(!response) {
                showToast('Update diagram JSON error.');
                return;
            }

            setCombinedProject(combineProjectData(response));

            const updatedDiagram = response.todosDiagrams.find((item: ITodoDiagram) => item.diagram_id === diagram_id);
            setTodoDiagram(updatedDiagram);

            showToast('Updated.', false);
        }
        else{
            // Insert ----------------------------
            const response = await insertDiagram(
                todo.todo_id,
                JSON.stringify(data),
            );

            setLoading(false);

            if(!response?.insertedId) {
                showToast('Insert diagram error.');
                return;
            }

            setCombinedProject(combineProjectData(response));

            const insertedId = response.insertedId || 0;
            const createdDiagram = response.todosDiagrams.find((item: ITodoDiagram) => item.diagram_id === insertedId);
            setTodoDiagram(createdDiagram);

            showToast('The new diagram is created.', false);
        }
    }, [
        data,
        setCombinedProject,
        setTodoDiagram,
        todo?.todo_id,
        todoDiagram,
        save,
    ]);

    useEffect(() => {
        const handleGlobalKeyDown = (evt: KeyboardEvent) => {

            if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
                evt.preventDefault();
                onClick();
                return;
            }

            if(evt.code === 'KeyD' && (evt.ctrlKey || evt.metaKey) && selectedElement) {
                evt.preventDefault();

                const copy = data.clone();

                const duplicated = duplicateElement(selectedElement);
                copy.children.push(duplicated);

                setData(copy);
                setSelectedElement(duplicated);
                setDataStack(updateHistory(dataStack, copy));
                return;
            }

            if(evt.code === 'KeyZ' && (evt.ctrlKey || evt.metaKey)) {
                evt.preventDefault();

                if(!dataStack?.length) return;

                const copy = [...dataStack];
                copy.pop();

                if(copy.length) {
                    const last = copy[copy.length - 1];
                    const svg = last ? DiagramSvg.fromJson(last) : null;
                    setData(svg);
                }

                setDataStack(copy);
                return;
            }

            if(evt.code === 'ArrowLeft' || evt.code === 'ArrowRight' || evt.code === 'ArrowUp' || evt.code === 'ArrowDown') {

                const element = evt.target as HTMLElement;
                if(element.getAttribute('contenteditable')) return;

                const foundIndex = data?.children?.findIndex(item => item.id === selectedElement?.id);
                if(foundIndex === -1) return;

                evt.preventDefault();

                const updatedElement = handleArrowKeys(evt.code, selectedElement);
                if(!updatedElement) return;

                const copy = data.clone();
                copy.children[foundIndex] = updatedElement;

                setData(copy);
                setSelectedElement(updatedElement);
                setDataStack(updateHistory(dataStack, copy));
                return;
            }

            if(evt.code === 'KeyC' && (evt.ctrlKey || evt.metaKey)) {

                const element = evt.target as HTMLElement;
                if(element.getAttribute('contenteditable')) return;

                setCopiedElement(duplicateElement(selectedElement));
                // evt.preventDefault();
                return;
            }

            if(evt.code === 'KeyV' && (evt.ctrlKey || evt.metaKey)) {

                const element = evt.target as HTMLElement;
                if(element.getAttribute('contenteditable')) return;

                if(!copiedElement) return;

                const copy = data.clone();
                copy.children.push(copiedElement);

                setData(copy);
                setSelectedElement(copiedElement);
                setDataStack(updateHistory(dataStack, copy));
                setCopiedElement(duplicateElement(selectedElement));
                // evt.preventDefault();
                return;
            }

            /*if(evt.code === 'Escape') {
                console.log('aaa')
                evt.stopPropagation();
                return;
            }*/
        };

        document.addEventListener('keydown', handleGlobalKeyDown);

        return () => {
            document.removeEventListener('keydown', handleGlobalKeyDown);
        };
    }, [
        onClick,
        data,
        setData,
        selectedElement,
        setSelectedElement,
        dataStack,
        setDataStack,
        copiedElement,
        setCopiedElement,
    ]);

    return (
        <>
            {
                isLoading &&
                <PreloaderIcon size={ 20 } color={ '#717985' } />
            }

            {
                !isLoading &&
                <button
                    className="ml-4"
                    title="Save Diagram"
                    type="button"
                    onClick={ onClick }>
                    <SaveIcon color={ '#000' } fill={ '#fff' } />
                </button>
            }
        </>
    )
};

export default SaveDiagramBtn;