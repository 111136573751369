// https://github.com/marcveens/react-drawio
// https://www.drawio.com/doc/faq/embed-mode
import { DrawIoEmbed, DrawIoEmbedRef, EventExport } from 'react-drawio';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDiagramsStore } from '../../data/diagrams-store';
import { getSelectedDiagram } from '../../domain/diagrams-provider';
import { updateDiagramSvg } from '../../data/diagrams-data-service';
import { showToast } from '../../../../common/domain/toast-provider';

const DiagramView = () => {

    const drawIoRef = useRef<DrawIoEmbedRef>(null);

    const { selected_item_id } = useParams();

    const diagramsResponse = useDiagramsStore(store => store.diagramsResponse);
    const selectedDiagram = getSelectedDiagram(diagramsResponse, Number(selected_item_id) || 0);

    const [imgData, setImgData] = useState<string | null>('');

    const onDiagramSave = async (data: EventExport) => {

        const currentPath = window.location.pathname; // Get the current URL path
        const currentSelectedItemId = Number(currentPath.split('/').pop()) || 0;

        if(!currentSelectedItemId) {
            showToast('Diagram is not selected.');
            return;
        }

        const _selectedDiagram = getSelectedDiagram(diagramsResponse, Number(currentSelectedItemId) || 0);
        if(!_selectedDiagram) {
            showToast('Diagram is not selected.');
            return;
        }

        const updatedSvg = data.data;
        setImgData(updatedSvg);

        const response = await updateDiagramSvg(
            _selectedDiagram.id,
            updatedSvg,
            _selectedDiagram.folder_id
        );

        if(!response) {
            showToast('Update SVG error.');
            return;
        }

        showToast('Updated OK.', false);
    };

    useEffect(() => {
        setImgData(selectedDiagram?.svg || '');
    }, [selectedDiagram]);

    return (
        <>
            {
                selectedDiagram &&
                <DrawIoEmbed
                    ref={ drawIoRef}
                    urlParameters={{
                        // ui: 'kennedy',

                        // Shows a 'Loading…' spinner while waiting
                        // for the diagram data to load in embed mode.
                        spin: true,

                        // Specifies whether libraries should be enabled in embed mode.
                        // The default is disabled.
                        // libraries: true,

                        noExitBtn: true,
                    }}
                    onExport={ onDiagramSave }
                    xml={ imgData }
                />
            }
        </>
    )
};

export default DiagramView;