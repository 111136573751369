import { TodoDiagramUseStore } from '../../../../data/store';
import { DiagramRectangleWithText } from '../../../../domain/diagram-elements/elements/diagram-rectangle-with-text';
import RectangleSelectionCircle from './rectangle-selection-circle';
import { DiagramArrow } from '../../../../domain/diagram-elements/elements/diagram-arrow';

interface IRectangleSelectionProps {
    useStore: TodoDiagramUseStore;
    shape: DiagramRectangleWithText;
}

const lineLength = DiagramArrow.defaultSize;

const RectangleSelection = ({ useStore, shape }: IRectangleSelectionProps) => {

    // Calculate positions for the 8 selection circles.
    const circles = [
        // Top-left
        {
            cx: shape.x,
            cy: shape.y,
            lineEndX: shape.x - lineLength,
            lineEndY: shape.y - lineLength,
            cursor: 'nw-resize',
        },
        // Top-center
        {
            cx: shape.x + shape.width / 2,
            cy: shape.y,
            lineEndX: shape.x + shape.width / 2,
            lineEndY: shape.y - lineLength,
            cursor: 'n-resize',
        },
        // Top-right
        {
            cx: shape.x + shape.width,
            cy: shape.y,
            lineEndX: shape.x + shape.width + lineLength,
            lineEndY: shape.y - lineLength,
            cursor: 'ne-resize',
        },
        // Middle-left
        {
            cx: shape.x,
            cy: shape.y + shape.height / 2,
            lineEndX: shape.x - lineLength,
            lineEndY: shape.y + shape.height / 2,
            cursor: 'w-resize',
        },
        // Middle-right
        {
            cx: shape.x + shape.width,
            cy: shape.y + shape.height / 2,
            lineEndX: shape.x + shape.width + lineLength,
            lineEndY: shape.y + shape.height / 2,
            cursor: 'e-resize',
        },
        // Bottom-left
        {
            cx: shape.x,
            cy: shape.y + shape.height,
            lineEndX: shape.x - lineLength,
            lineEndY: shape.y + shape.height + lineLength,
            cursor: 'sw-resize',
        },
        // Bottom-center
        {
            cx: shape.x + shape.width / 2,
            cy: shape.y + shape.height,
            lineEndX: shape.x + shape.width / 2,
            lineEndY: shape.y + shape.height + lineLength,
            cursor: 's-resize',
        },
        // Bottom-right
        {
            cx: shape.x + shape.width,
            cy: shape.y + shape.height,
            lineEndX: shape.x + shape.width + lineLength,
            lineEndY: shape.y + shape.height + lineLength,
            cursor: 'se-resize',
        },
    ];

    return (
        <g>
            {
                circles.map((circle, index) => (
                    <RectangleSelectionCircle
                        key={ index }
                        index={ index }
                        cx={ circle.cx }
                        cy={ circle.cy }
                        lineEndX={ circle.lineEndX }
                        lineEndY={ circle.lineEndY }
                        cursor={ circle.cursor }
                        shape={ shape }
                        useStore={ useStore }
                    />
                ))
            }
        </g>
    )
};

export default RectangleSelection;