import { MouseEvent } from 'react';
import { EditIcon } from '../../../../../../common/ui/icons';
import { sendCloseActionsEvent } from '../../../../../../common/ui/actions';

interface ITodoDiagramEditButtonProps {
    startEditDiagram: () => void;
}

const TodoDiagramEditButton = ({ startEditDiagram }: ITodoDiagramEditButtonProps) => {

    const handleClick = (evt: MouseEvent) => {
        evt.stopPropagation();
        evt.preventDefault();

        startEditDiagram();
        sendCloseActionsEvent();
    };

    return (
        <button
            className="flex border border-stone-300 bg-stone-200 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
            onClick={ handleClick }
            type="button"
            title="Edit Diagram Description">
            <EditIcon size={ 20 } />
        </button>
    )
};

export default TodoDiagramEditButton;