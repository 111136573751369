import TodoDiagramEntryPoint from '../../../todo-diagram-editor/diagram-entry-point';
import { updateNoteDiagram } from '../../data/planner-data-service';
import { showToast } from '../../../../common/domain/toast-provider';
import { combineProjectData } from '../../domain/planner-provider';
import { usePlannerStore } from '../../data/planner-store';

interface ITodayNoteDiagramProps {
    noteDiagram: string;
}

const TodayNoteDiagram = ({ noteDiagram } : ITodayNoteDiagramProps) => {

    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);

    const save = async (json: string) => {

        const response = await updateNoteDiagram(
            json,
        );

        if(!response) {
            showToast('Update note diagram error.');
            return;
        }

        setCombinedProject(combineProjectData(response));
    };


    return (
        <div className="mt-8 my-24">
            <TodoDiagramEntryPoint
                todo={ null }
                todoDiagram={ null }
                onClose={ () => {} }
                save={ save }
                outerJson={ noteDiagram }
            />
        </div>
    )
};

export default TodayNoteDiagram;