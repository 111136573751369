import { useEffect, useState } from 'react';
import { createFoodCalendar } from '../../domain/food-calendar-provider';
import { IFoodCalendarYear } from '../../food-interfaces';
import { getFoodLogsMin } from '../../data/food-data-service';
import { endOfYear, startOfYear } from 'date-fns';
import MonthView from './month-view';

const YearView = () => {

    const [calendarData, setCalendarData] = useState<IFoodCalendarYear|null>(null);

    useEffect(() => {

        (async () => {
            const date = new Date();

            const fromDate = startOfYear(date).getTime();
            const toDate = endOfYear(date).getTime();
            const data = await getFoodLogsMin(fromDate, toDate);

            const result = createFoodCalendar(date.getFullYear(), data || []);
            setCalendarData(result);
        })();

    }, []);

    return (
        <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 border-b border-r mt-4">
            {
                calendarData?.months?.map(month => {
                    return (
                        <MonthView
                            key={ month.id }
                            month={ month }
                        />
                    )
                })
            }
        </div>
    )
};

export default YearView;