import { useState, MouseEvent } from 'react';
import { deleteDiagram } from '../../../../data/planner-data-service';
import { DeleteIcon, PreloaderIcon } from '../../../../../../common/ui/icons';
import { ITodoDiagram } from '../../../../planner-interfaces';
import { showToast } from '../../../../../../common/domain/toast-provider';
import { combineProjectData } from '../../../../domain/planner-provider';
import { usePlannerStore } from '../../../../data/planner-store';

interface ITodoDeleteDiagramButtonProps {
    diagram: ITodoDiagram;
}

const TodoDeleteDiagramButton = (props: ITodoDeleteDiagramButtonProps) => {

    const { diagram } = props;

    const [isLoading, setLoading] = useState(false);

    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);

    const deleteDiagramHandler = async (evt: MouseEvent<HTMLButtonElement>) => {
        evt.stopPropagation();
        evt.preventDefault();

        setLoading(true);
        const response = await deleteDiagram(diagram.diagram_id);
        setLoading(false);

        if(!response?.isValid) {
            showToast('Delete diagram error.');
            return;
        }

        setCombinedProject(combineProjectData(response));

        showToast('Delete diagram OK.', false);
    };

    return (
        <button
            onClick={ deleteDiagramHandler }
            type="button"
            title="Delete Diagram"
            className="todo-diagram__delete flex border border-pink-200 bg-pink-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn">

            {
                !isLoading &&
                <DeleteIcon size={ 20 } classes="pointer-events-none" />
            }

            {
                isLoading &&
                <PreloaderIcon size={ 20 } />
            }
        </button>
    )
};

export default TodoDeleteDiagramButton;