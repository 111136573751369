import { useAuthStatus } from '../auth/hooks/auth-status-hook';
import Preloader from '../../common/ui/preloader';
import EntryPoint from './ui/entry-point';

const DrawIO = () => {

    const { loading } = useAuthStatus();

    return (
        <div className="text-slate-800">

            { loading && <Preloader/> }

            {
                !loading &&
                <EntryPoint />
            }
        </div>
    )
};

export default DrawIO;