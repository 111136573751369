import { useDiagramsStore } from '../../data/diagrams-store';
import { FolderIcon } from '../../../../common/ui/icons';
import DiagramsFolderTitle from './folder-title';
import DiagramsFolderDescription from './folder-description';

const FolderView = () => {
    const diagramsResponse = useDiagramsStore(store => store.diagramsResponse);

    return (
        <div className="flex flex-col min-h-screen w-full xl:w-[1200px] max-w-full mx-auto px-10 py-4">

            {
                diagramsResponse?.selectedFolder &&
                <>
                    <DiagramsFolderTitle
                        diagramsFolder={ diagramsResponse.selectedFolder }
                    />

                    <DiagramsFolderDescription
                        folder={ diagramsResponse.selectedFolder }
                    />
                </>
            }

            <div className="flex justify-center items-center py-10 flex-1">
                <FolderIcon
                    size={ 200 }
                    strokeWidth={ 1 }
                    color={ '#d9d9d9' }
                />
            </div>
        </div>
    )
};

export default FolderView;