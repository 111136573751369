import { DragEvent } from 'react';
import { DiagramElementType } from '../../../domain/diagram-elements/diagram-element-type-enum';
import { getSvgCenter, TODO_DIAGRAM_TOOLBAR_DRAG_CLASS, updateHistory } from '../../../domain/todo-diagram-provider';
import { TodoDiagramUseStore } from '../../../data/store';
import { v4 as uuidv4 } from 'uuid';
import { DiagramLine } from '../../../domain/diagram-elements/elements/diagram-line';

interface ILineBtnProps {
    useStore: TodoDiagramUseStore;
}

const LineBtn = ({ useStore }: ILineBtnProps) => {

    const data = useStore(state => state.data);
    const setData = useStore(state => state.setData);
    const setSelectedElement = useStore(state => state.setSelectedElement);
    const dataStack = useStore(state => state.dataStack);
    const setDataStack = useStore(state => state.setDataStack);

    const onClick = () => {
        const copy = data.clone();
        copy.children = [...(copy.children || [])];

        const { cx, cy } = getSvgCenter(data);

        const shape = new DiagramLine(
            uuidv4(),
            cx - DiagramLine.defaultSize/2,
            cy + DiagramLine.defaultSize/2,
            cx + DiagramLine.defaultSize/2,
            cy - DiagramLine.defaultSize/2,
            '#000',
            2,
        );
        copy.children.push(shape);

        setData(copy);
        setDataStack(updateHistory(dataStack, copy));
        setSelectedElement(shape);
    };

    const sourceOnDragStart = (evt: DragEvent<HTMLButtonElement>) => {
        evt.stopPropagation();

        // Clear the actions' data cache (for all formats/types)
        evt.dataTransfer.clearData();

        evt.dataTransfer.setData('text/plain', DiagramElementType.Line.toString());

        // https://stackoverflow.com/questions/19639969/html5-dragend-event-firing-immediately
        ((_currentTarget) => {
            window.setTimeout(() => {
                document.body.classList.add(TODO_DIAGRAM_TOOLBAR_DRAG_CLASS);
            }, 0)
        })(evt.currentTarget);
    };

    const sourceOnDragEnd = (evt: DragEvent<HTMLButtonElement>) => {
        evt.stopPropagation();
        document.body.classList.remove(TODO_DIAGRAM_TOOLBAR_DRAG_CLASS);
    };

    const sourceOnDrag = (evt: DragEvent<HTMLButtonElement>) => {
        evt.stopPropagation();
    };

    return (
        <button
            className="mr-1"
            draggable={ true }
            onDragStart={ sourceOnDragStart }
            onDragEnd={ sourceOnDragEnd }
            onDrag={ sourceOnDrag }
            onClick={ onClick }
            title="Rectangle"
            type="button">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                 className="icon icon-tabler icons-tabler-outline icon-tabler-line">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M6 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"/>
                <path d="M18 6m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"/>
                <path d="M7.5 16.5l9 -9"/>
            </svg>
        </button>
    )
};

export default LineBtn;