const RootView = () => {
    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-900">
            <img
                className="rounded-xl"
                width="500px"
                src="/img/door-1.webp"
                alt=""
            />
        </div>
    )
};

export default RootView;