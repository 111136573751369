const ToolbarDivider = () => {
    return (
        <div
            className="w-0.5 h-4 bg-slate-300 border-r border-r-white ml-2 mr-4"
            data-orientation="vertical"
            aria-orientation="vertical"
            role="separator"
        />
    )
};

export default ToolbarDivider;