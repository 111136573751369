import {
    eachDayOfInterval,
    eachMonthOfInterval,
    endOfYear,
    startOfYear,
    startOfMonth,
    endOfMonth,
    format,
    isToday,
    startOfDay, getDay
} from 'date-fns';
import { IFoodCalendarDay, IFoodCalendarMonth, IFoodCalendarYear, IFoodLog } from '../food-interfaces';

export const createFoodCalendar = (year: number, logs: IFoodLog[]) : IFoodCalendarYear => {

    const groupedLogsMap = logs.reduce((acc, log) => {

            const dateStart = startOfDay(log.date).getTime();

            if (!acc.has(dateStart)) {
                acc.set(dateStart, []);
            }

            acc.get(dateStart)?.push(log);

            return acc;
        },
        new Map<number, IFoodLog[]>()
    );

    const result: IFoodCalendarYear = {
        year,
        months: [],
    };

    const date = new Date(year, 0, 1);

    const months: Date[] = eachMonthOfInterval({
        start: startOfYear(date),
        end: endOfYear(date),
    });

    for(const monthStartDate of months) {

        const calendarMonth: IFoodCalendarMonth = {
            id: `${ year }-${ monthStartDate.getTime() }`,
            monthStartDate,
            monthName: format(monthStartDate, 'MMMM yyyy'),
            days: [],
            startDayOfWeek: getDay(monthStartDate), // 0 (Sunday) to 6 (Saturday)
        };

        const days = eachDayOfInterval({
            start: startOfMonth(monthStartDate),
            end: endOfMonth(monthStartDate),
        });

        for(const dayStartDate of days) {

            const dayLogs = groupedLogsMap.get(dayStartDate.getTime()) || [];
            const calories = dayLogs.reduce((sum, item) => sum + item.calories * item.quantity, 0);

            const calendarDay: IFoodCalendarDay = {
                id: `${ year }-${ monthStartDate.getTime() }-${ dayStartDate.getTime() }`,
                dayName: format(dayStartDate, 'd'),
                dayStartDate,
                isTodayDay: isToday(dayStartDate),
                calories,
            };

            calendarMonth.days.push(calendarDay);
        }

        result.months.push(calendarMonth);
    }

    return result;
};