import YearView from './year-view';
import FoodCalendarTitle from './food-calendar-title';

const FoodCalendarHome = () => {

    return (
        <div className="max-w-full mx-auto py-4">
            <FoodCalendarTitle />
            <YearView />
        </div>
    )
};

export default FoodCalendarHome;