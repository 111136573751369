import Actions from '../../../../../common/ui/actions';
import TodoDeleteDiagramButton from './actions/todo-diagram-delete';
import { ITodoDiagram } from '../../../planner-interfaces';
import TodoDiagramEditDescriptionButton from './todo-diagram-edit-description-btn';
import TodoDiagramEditButton from './actions/todo-diagram-edit-btn';
import DragDiagramTodoButton from './actions/drag-diagram-todo-btn';
import TodoDiagramEditDescription from './todo-diagram-edit-description';
import { MouseEvent, useState } from 'react';

interface ITodoDiagramTitleProps {
    diagram: ITodoDiagram;
    startEditDiagram: () => void;
}

enum DescriptionMode {
    DEFAULT = 0,
    EDIT = 1,
}

const TodoDiagramTitle = ({ diagram, startEditDiagram }: ITodoDiagramTitleProps) => {

    const [descriptionMode, setDescriptionMode] = useState<DescriptionMode>(DescriptionMode.DEFAULT);

    const startEditDescription = () => {
        setDescriptionMode(DescriptionMode.EDIT);
    };

    const stopEditDescription = () => {
        setDescriptionMode(DescriptionMode.DEFAULT);
    };

    const onDescriptionClick = (evt: MouseEvent<HTMLButtonElement>) => {
        evt.stopPropagation();

        // double-click
        if(evt.detail === 2) {
            startEditDescription();
        }
    };

    return (
        <h3 className="flex items-center justify-between px-4 py-2 border-b">

            {
                descriptionMode === DescriptionMode.DEFAULT &&
                <button
                    onClick={ onDescriptionClick }
                    className="flex-1 text-left"
                    type="button">
                    { diagram.diagram_desc || 'Diagram' }
                </button>
            }

            {
                descriptionMode === DescriptionMode.EDIT &&
                <TodoDiagramEditDescription
                    diagram={ diagram }
                    stopEditDescription={ stopEditDescription }
                />
            }

            <Actions classes="todo-diagram__actions" mr="mr-0" gridSize={ 2 }>

                <TodoDeleteDiagramButton
                    diagram={ diagram }
                />

                <TodoDiagramEditDescriptionButton
                    startEditDescription={ startEditDescription }
                />

                <TodoDiagramEditButton
                    startEditDiagram={ startEditDiagram }
                />

                <DragDiagramTodoButton
                    diagram={ diagram }
                />
            </Actions>
        </h3>
    )
};

export default TodoDiagramTitle;