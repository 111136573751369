import { useState } from 'react';
import { ITodo } from '../../planner-interfaces';
import TodoTitle from './todo-title';
import TodoDescription from './description/todo-description';
import NewTodo from './todo-add';
import TodoDragTarget from './drag/todo-drag-target';
import { PlusIcon } from '../../../../common/ui/icons';
import TodoDiagramDragTarget from './drag/todo-diagram-drag-target';

enum AddTodoMode {
    DEFAULT = 0,
    ADDING = 1,
}

interface ITodoProps {
    todo: ITodo;
    isFirst: boolean;
    isLast: boolean;
    nestLevel: number;
    showTodoLink?: boolean;
}

const Todo = (props: ITodoProps) => {

    const { todo, isFirst, isLast, nestLevel } = props;

    const[addTodoMode, setAddTdoMode] = useState<AddTodoMode>(AddTodoMode.DEFAULT);

    const startAddingNewTodo = () => {
        setAddTdoMode(AddTodoMode.ADDING);
    };

    const stopAddingNewTodo = () => {
        setAddTdoMode(AddTodoMode.DEFAULT);
    };

    return (
        <div className={ `todo mb-2 relative ${ (isLast || nestLevel > 0) ? '' : 'border-b pb-2' }` } data-todo-id={ todo.todo_id }>

            {
                isFirst &&
                <TodoDragTarget
                    targetProjectId={ todo.project_id }
                    targetTodoId={ todo.todo_id }
                    targetOrder={ todo.todo_order }
                    isBefore={ true }
                    targetAsParent={ false }
                />
            }

            <TodoTitle
                todo={ todo }
                startAddingNewTodo={ startAddingNewTodo }
                showTodoLink={ props.showTodoLink }
            />

            <TodoDescription todo={ todo } />

            {
                todo.isOpened && todo.nested?.length > 0 &&
                <div className="ml-8 xl:ml-14 mt-4">
                    {
                        todo.nested.map(
                            (nestedTodo, index) =>
                                <Todo
                                    todo={ nestedTodo }
                                    key={ `todo-${ nestedTodo.todo_id}-${ index }` }
                                    isFirst={ index === 0 }
                                    isLast={ index === todo.nested.length - 1 }
                                    nestLevel={ nestLevel + 1 }
                                />
                        )
                    }

                    <button
                        className={ 'nested-todo__add-task flex items-center text-sm text-slate-400 mb-10' }
                        onClick={ startAddingNewTodo }
                        type="button">
                        <div className="mr-2"><PlusIcon /></div>
                        <div>Add Nested Task</div>
                    </button>
                </div>
            }

            {
                todo.nested?.length <= 0 &&
                <TodoDragTarget
                    targetProjectId={ todo.project_id }
                    targetTodoId={ todo.todo_id }
                    targetOrder={ todo.todo_order }
                    isBefore={ false }
                    targetAsParent={ true }
                    classes="ml-8 xl:ml-14 mt-4 mb-4"
                />
            }

            {
                (addTodoMode === AddTodoMode.ADDING) &&
                <div className="my-2">
                    <NewTodo
                        project_id={ todo.project_id }
                        section_id={ todo.section_id }
                        parent_todo_id={ todo.todo_id }
                        parent_todo_title={ todo.todo_title }
                        order={
                            todo.nested?.length > 0 ?
                                (todo.nested[todo.nested.length - 1].todo_order + 1)
                                : 0
                        }
                        cancel={ stopAddingNewTodo }
                    />
                </div>
            }

            <TodoDragTarget
                targetProjectId={ todo.project_id }
                targetTodoId={ todo.todo_id }
                targetOrder={ todo.todo_order }
                isBefore={ false }
                targetAsParent={ false }
            />

            <TodoDiagramDragTarget
                new_todo_id={ todo.todo_id }
            />
        </div>
    )
};

export default Todo;