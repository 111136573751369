import { useFinanceStore } from '../../../../data/finance-store';
import { FINANCE_INCOME_CATEGORY_ID } from '../../../../domain/finance-provider';

interface ICategoriesRadioFilter {
    categoryID: number;
    categoryName: string;
    categoryAmount: number;
    categoryColor: string;
}

const CategoriesRadioFilter = ({ categoryID, categoryName, categoryAmount }: ICategoriesRadioFilter) => {

    const financeTransactionsData = useFinanceStore(store => store.financeTransactionsData);
    const transactionsSelectedCategoryID = useFinanceStore(store => store.transactionsSelectedCategoryID);
    const setTransactionsSelectedCategoryID = useFinanceStore(store => store.setTransactionsSelectedCategoryID);
    const setTransactionsPageNumber = useFinanceStore(store => store.setTransactionsPageNumber);

    const onChange = () => {
        setTransactionsPageNumber(0);
        setTransactionsSelectedCategoryID(categoryID);
    };

    const income = Math.abs(financeTransactionsData?.incomeSum ?? 0);
    const amount = Math.abs(categoryAmount);
    const percent = (income !== 0) ? (100 * amount / income) : 0;

    return (
        <label
                data-id={ categoryID }
                key={ categoryID }
                className="mr-3 flex items-start">
            <input
                type="radio"
                name="radio-categories"
                value={ categoryID }
                checked={ transactionsSelectedCategoryID === categoryID }
                onChange={ onChange }
            />
            <div className="flex flex-col items-start ml-2" style={{
                marginTop: '-2px',
            }}>
                <span className="mr-2 mb-1">{ categoryName ?? 'Без Категории' }</span>

                {
                    categoryID === FINANCE_INCOME_CATEGORY_ID &&
                    <span
                        className="text-xs bg-gray-100 rounded px-2 py-px inline-block">{ income.toLocaleString() } ₪ = 100%</span>
                }

                {
                    categoryID !== FINANCE_INCOME_CATEGORY_ID && amount !== 0 &&
                    <span
                        className="text-xs bg-gray-100 rounded px-2 py-px inline-block"
                        style={ {
                            // backgroundColor: categoryColor,
                        }}>{ amount.toLocaleString() } ₪ = { percent.toFixed(2) }%</span>
                }
            </div>
        </label>
    )
};

export default CategoriesRadioFilter;